import { Pipe, PipeTransform } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

@Pipe({
    name: "getError",
    standalone: true,
})
export class GetErrorPipe implements PipeTransform {

    private defaultErrors = {
        required: "errors.required",
    };

    transform(value: ValidationErrors, customErrors: { [key: string]: string } = {}): string {
        if (!value) {
            return "";
        }
        const errors = Object.keys(value);
        return { ...this.defaultErrors, ...customErrors }[errors[0]];
    }
}
