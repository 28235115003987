import { Component, Input, OnInit } from "@angular/core";
import { AppService } from "../../services/app.service";

@Component({
    selector: "app-loading-modal",
    templateUrl: "./loading-modal.component.html",
    styleUrls: ["./loading-modal.component.scss"],
})
export class LoadingModalComponent implements OnInit {
    @Input() message = "Subiendo datos...";

    constructor(
        public appSrv: AppService
    ) {
    }

    ngOnInit() {
    }

}
