import { BehaviorSubject, Observable } from "rxjs";
import { Session } from "../models/session/session.model";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class SessionService {
    private _session: BehaviorSubject<Session | null>;

    get session(): Session | null {
        return this._session.getValue();
    }

    get sessionObservable(): Observable<Session | null> {
        return this._session.asObservable();
    }

    constructor(private storageSrv: StorageService) {
        this.setup();
    }

    private setup() {
        this.setupVars();
    }

    private setupVars() {
        this._session = new BehaviorSubject<Session | null>(null);
    }

    public setSession(session: Session) {
        this._session.next(session);
        this.storageSrv
            .setSession(session)
            .then()
            .catch((e) => {
                throw new Error(e);
            });
    }

    public clearSession() {
        this._session.next(null);
    }

    public setDocumentSession(payload: Record<string, any>) {
        const session = this.session;
        if (!session) {
            return;
        }

        session.document = _.assign(this.session.document, payload);

        this.setSession(session);
    }
}
