import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { VerifyOto } from "../models/verify";
import { map } from "rxjs/operators";
import { BuilderPatternService } from "./builder-pattern.service";

@Injectable({
    providedIn: "root",
})
export class OneToOneService {
    constructor(
        private http: HttpClient,
        private builderPatternSrv: BuilderPatternService,
    ) {
    }

    public oneToOneVerify(request: {
        eventId: string;
        image1: string;
        image2: string;
    }): Observable<VerifyOto> {
        let header = null;

        if (request.eventId) {
            header = new HttpHeaders({
                "Request-Id": request.eventId,
            });

        }
        return this.http
            .post(`${environment.api}/api/v2/oto/verify`, request, {
                headers: header,
            })
            .pipe(
                map((response: any) =>
                    this.builderPatternSrv.buildOtoVerifyClass(response),
                ),
            );
    }
}
