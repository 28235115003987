import { Component, OnInit } from "@angular/core";
import { FlowService } from "../../services/tools/flow.service";
import { NotificationService } from "../../services/notification.service";
import { first } from "rxjs/operators";
import { DashboardService } from "../../services/dashboard.service";
import { KeyStep } from "../../services/steps/step.interface";
import { CustomError } from "src/app/classes/custom-error";

@Component({
    selector: "app-permissions-step",
    templateUrl: "./permissions-step.component.html",
    styleUrls: ["./permissions-step.component.scss"],
})
export class PermissionsStepComponent implements OnInit {
    lat: number;
    lng: number;

    permissionState: PermissionState = "prompt";

    statusText = {
        denied: "step-permissions.denied",
        granted: "step-permissions.granted",
        prompt: "step-permissions.prompt",
    };

    terms = false;

    constructor(
        public flowSrv: FlowService,
        private notifySrv: NotificationService,
        private dashboardSrv: DashboardService,
    ) {}

    ngOnInit(): void {
        this.handlePermission();
    }

    async next() {
        await this.flowSrv.goToStepByKey(KeyStep.DOCUMENT_CAPTURE);
    }

    private async handlePermission(): Promise<void> {
        const result = await navigator.permissions.query({
            name: "geolocation",
        });

        this.permissionState = result.state;

        if (result.state === "granted") {
            this.getLocation()
                .then()
                .catch((e) => {
                    throw new Error(e);
                });
        } else if (result.state === "denied") {
            await this.flowSrv.goToStepByKey(KeyStep.PERMISSION_DENIED);
        }

        result.onchange = async () => {
            this.permissionState = result.state;
            if (result.state === "denied") {
                await this.flowSrv.goToStepByKey(KeyStep.PERMISSION_DENIED);
            }
        };
    }

    async getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position) {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    this.permissionState = "granted";
                    this.dashboardSrv
                        .setLocation(this.lat, this.lng)
                        .pipe(first())
                        .toPromise()
                        .then()
                        .catch((e) => {
                            console.error(e);
                            const customErr: CustomError = e;
                            if (customErr?.message) {
                                this.notifySrv.showToastDanger(
                                    customErr.message,
                                );
                            }
                            throw new Error(e);
                        });
                }
            });
        } else {
            await this.notifySrv.showToastDanger(
                "El navegador no es compatible con este servicio",
            );
        }
    }
}
