import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BuilderPatternService } from "./builder-pattern.service";

interface EnrollRequest {
    fullName: string;
    country: string;
    identification: {
        type: string;
        key: string;
        value: string;
    };
    finger: number;
    fingerprint: string;
}

@Injectable({
    providedIn: "root",
})
export class FingerprintService {
    constructor(
        private http: HttpClient,
        private builderPatternSrv: BuilderPatternService,
    ) {
    }

    public enroll(request: EnrollRequest): Observable<any> {
        return this.http
            .post(`${environment.api}/api/v2/fingerprint/enroll`, request);
    }
}
