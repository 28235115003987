import { Component, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "../../services/notification.service";
import { ModalController } from "@ionic/angular";
import { first } from "rxjs/operators";
import { DashboardService } from "../../services/dashboard.service";

@Component({
    selector: "app-request-location-modal",
    templateUrl: "./request-location-modal.component.html",
    styleUrls: ["./request-location-modal.component.scss"],
})
export class RequestLocationModalComponent implements OnInit {
    lat: number;
    lng: number;

    constructor(
        private notifySrv: NotificationService,
        private modalCtrl: ModalController,
        private dashboardSrv: DashboardService,
    ) {
    }

    ngOnInit() {
    }

    async getLocation(): Promise<void> {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position) {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    this.dashboardSrv.setLocation(this.lat, this.lng).pipe(first()).toPromise().then();
                }
                this.modalCtrl.dismiss();
            });
        } else {
            await this.notifySrv.showToastDanger(
                "El navegador no es compatible con este servicio",
            );
        }
    }
}
