export class Document {
    id: string;
    status: boolean;
    message: string;
    documentType: number;
    documentData: DocumentData;
    documentMetadata: string;
    processTime: number;
    image?: string;
    toObjectRequest(): any {
        return {
            eventId: this.id,
            document: this.image,
        };
    }
}

export class DocumentData {
    ineFrontData: IneFrontData;
    ineBackData: IneBackData;
}

export class IneFrontData {
    address: AddressData;
    birthDate: string;
    birthPlace: string;
    curp: string;
    documentImage: DocumentImage;
    electorKey: string;
    emissionYear: string;
    gender: string;
    geoElectoralData: GeoElectoralData;
    motherSurname: string;
    name: string;
    registerYear: string;
    rfc?: string;
    surname: string;
    validUntil: string;
    registerMonth: string;
}

export class IneBackData {
    qrCodes: string;
    barCodes: string;
    identificationOCR: string;
    numIdentificationCredential: AddressData;
    mechanicalReadingZone: string;
}

export class AddressData {
    city: string;
    council: string;
    neighborhood: string;
    state: string;
    street: string;
    zipCode: string;
}

export class GeoElectoralData {
    cityCode: string;
    folio: string;
    localityCode: string;
    sectionCode: string;
    stateCode: string;
}

export class DateObject {
    day: number;
    month: number;
    year: number;
}

export class DocumentImage {
    photo: string;
    position: Position;
}

export class Position {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export class DocumentVerify {
    eventId: string;
    status: boolean;
    evaluation: number;
    message: string;
}
