// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 3rem 2rem 2rem 2rem;
}

.icon-container {
  font-size: 60px;
}

.btn-primary {
  margin-top: 5rem;
  height: 60px;
  width: 100%;
  border-radius: 8px;
  background: #1ecad3;
  color: white;
  font-size: 19px;
}
.btn-primary.no-margin {
  margin-top: 0px !important;
}

.network {
  color: #cacaca;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/alert-modal/alert-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AACA;EACI,gBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AAEJ;AAAI;EACI,0BAAA;AAER;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".container{\n    padding: 3rem 2rem 2rem 2rem;\n}\n.icon-container{\n    font-size: 60px;\n}\n\n.btn-primary {\n    margin-top: 5rem;\n    height: 60px;\n    width: 100%;\n    border-radius: 8px;\n    background: #1ecad3;\n    color: white;\n    font-size: 19px;\n\n    &.no-margin{\n        margin-top: 0px !important;\n    }\n}\n\n.network {\n    color: #cacaca;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
