// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-spinner {
  height: 4rem;
  width: 4rem;
  color: var(--ion-color-primary);
}

h3 {
  margin-top: 1rem;
  color: var(--ion-color-primary);
  font-size: 1rem;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,+BAAA;AACF;;AAEA;EACE,gBAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":["ion-spinner {\n  height: 4rem;\n  width: 4rem;\n  color: var(--ion-color-primary);\n}\n\nh3 {\n  margin-top: 1rem;\n  color: var(--ion-color-primary);\n  font-size: 1rem;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
