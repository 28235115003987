import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StepInterface } from "../steps/step.interface";
import { DocumentCaptureFrontService } from "../steps/document-capture-front.service";
import { VerifyDataService } from "../steps/verify-data.service";
import { IdentityVerificationService } from "../steps/identity-verification.service";
import { ResutsService } from "../steps/resuts.service";
import { DocumentCaptureReverseService } from "../steps/document-capture-reverse.service";
import { DocumentCaptureService } from "../steps/document-capture.service";
import { StorageService } from "../storage.service";
import { AboutService } from "../steps/about.service";
import { PermissionsService } from "../steps/permissions.service";
import { PermissionDeniedService } from "../steps/permission-denied.service";
import { FingerprintService } from "../steps/fingerprint.service";
import { ToolsService } from "../tools.service";

@Injectable({
    providedIn: "root",
})
export class FlowService {
    private _currentStepIndex: number;
    private _stepList: Array<StepInterface>;
    private _onChangeStep: BehaviorSubject<StepInterface>;

    get stepList(): Array<StepInterface> {
        return this._stepList;
    }

    get previousStep(): StepInterface | null {
        return this._stepList[this._currentStepIndex - 1] ?? null;
    }

    get currentStep(): StepInterface {
        return this._stepList[this._currentStepIndex];
    }

    get currentStepIndex(): number {
        return this._currentStepIndex;
    }

    get nextStep(): StepInterface | null {
        return this._stepList[this._currentStepIndex + 1] ?? null;
    }

    get hasPreviousStep(): boolean {
        return this.previousStep !== null;
    }

    get hasNextStep(): boolean {
        return this.nextStep !== null;
    }

    get isFirstCurrentStep(): boolean {
        return this.currentStepIndex === 0;
    }

    get isLastCurrentStep(): boolean {
        return this.currentStepIndex === this._stepList.length - 1;
    }

    get onChangeStep(): Observable<StepInterface> {
        return this._onChangeStep.asObservable();
    }

    constructor(
        private storageSrv: StorageService,
        private toolsService: ToolsService,
    ) {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private async setupVars() {
        const step = localStorage.getItem("_cap_step");
        const numeroPaso = step.substring(5, 6);
        this._currentStepIndex = parseInt(numeroPaso) - 1;
        this._stepList = [
            inject(AboutService),
            inject(PermissionsService),
            inject(PermissionDeniedService),
            inject(DocumentCaptureService),
            inject(DocumentCaptureFrontService),
            inject(DocumentCaptureReverseService),
            inject(VerifyDataService),
            inject(IdentityVerificationService),
        ];
        if (this.toolsService.hasFingerprint()) {
            this._stepList.push(inject(FingerprintService));
        }
        this._stepList.push(inject(ResutsService));
        this._onChangeStep = new BehaviorSubject<StepInterface>(this.currentStep);
        const stepKey = await this.storageSrv.getFlowStep();
        if (stepKey) {
            await this.goToStepByKey(stepKey);
        }
    }

    public async goToPreviousStep(): Promise<void> {
        if (this.hasPreviousStep) {
            await this.currentStep.onLeave();

            this._currentStepIndex--;
            this._onChangeStep.next(this.currentStep);
            await this.storageSrv.setFlowStep(this.currentStep.key);

            await this.currentStep.onEnter();

            await this.currentStep.onProgress();
        }
    }

    public async goToNextStep(): Promise<void> {
        if (this.hasNextStep) {
            await this.currentStep.onLeave();

            this._currentStepIndex++;
            this._onChangeStep.next(this.currentStep);
            await this.storageSrv.setFlowStep(this.currentStep.key);

            await this.currentStep.onEnter();

            await this.currentStep.onProgress();
        }
    }

    public async goToStepByKey(key: string): Promise<void> {
        const indexStepFound = this.getIndexOfStepByKey(key);
        if (indexStepFound === -1) {
            return;
        }

        await this.currentStep.onLeave();

        this._currentStepIndex = indexStepFound;
        this._onChangeStep.next(this.currentStep);
        await this.storageSrv.setFlowStep(this.currentStep.key);

        await this.currentStep.onEnter();

        await this.currentStep.onProgress();
    }

    public clear(): void {
        this._currentStepIndex = 0;
        this._onChangeStep = new BehaviorSubject<StepInterface>(this.currentStep);
    }

    private getIndexOfStepByKey(key: string): number {
        return this._stepList.findIndex((step) => step.key === key);
    }
}
