// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: #ffffff;
}
ion-content img {
  width: 147px;
  height: auto;
}

.text-center {
  color: white;
}
.text-center h3 {
  font-size: 17px;
  margin-top: 8px;
  margin-bottom: 1rem;
}
.text-center p {
  padding-top: 0.5rem;
  font-size: 12px;
}

ion-progress-bar {
  margin-top: 1.5rem;
  width: 185px;
  --buffer-background: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/loading-modal/loading-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;AAAI;EACI,YAAA;EACA,YAAA;AAER;;AAEA;EACI,YAAA;AACJ;AAAI;EACI,eAAA;EACA,eAAA;EACA,mBAAA;AAER;AACI;EACI,mBAAA;EACA,eAAA;AACR;;AAGA;EACI,kBAAA;EACA,YAAA;EACA,yCAAA;AAAJ","sourcesContent":["ion-content {\n    --background: #ffffff;\n    img {\n        width: 147px;\n        height: auto;\n    }\n}\n\n.text-center {\n    color: white;\n    h3 {\n        font-size: 17px;\n        margin-top: 8px;\n        margin-bottom: 1rem;\n    }\n\n    p {\n        padding-top: 0.5rem;\n        font-size: 12px;\n    }\n}\n\nion-progress-bar {\n    margin-top: 1.5rem;\n    width:185px;\n    --buffer-background: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
