import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertModalComponent } from "../../modals/alert-modal/alert-modal.component";
import { LoadingModalComponent } from "../../modals/loading-modal/loading-modal.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { HttpClientModule } from "@angular/common/http";
import { LoadingComponent } from "../../components/loading/loading.component";
import { CheckDocumentFrontComponent } from "src/app/steps/check-document-front/check-document-front.component";
import { CheckDocumentBackComponent } from "../../steps/check-document-back/check-document-back.component";
import { VerifyComponent } from "src/app/steps/verify/verify.component";
import { IverificationComponent } from "../../steps/iverification/iverification.component";
import { RequestLocationModalComponent } from "../../modals/request-location-modal/request-location-modal.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ResultsComponent } from "../../steps/results/results.component";
import { StepListFlowComponent } from "src/app/components/step-list-flow/step-list-flow.component";
import { DocumentCaptureComponent } from "../../steps/document-capture/document-capture.component";
import { TranslateModule } from "@ngx-translate/core";
import { AboutStepComponent } from "../../steps/about-step/about-step.component";
import { PermissionsStepComponent } from "../../steps/permissions-step/permissions-step.component";
import { PermissionDeniedStepComponent } from "../../steps/permission-denied-step/permission-denied-step.component";
import { FingerprintStepComponent } from "../../steps/fingerprint-step/fingerprint-step.component";
import { GetErrorPipe } from "../../pipes/get-error.pipe";
import { CheckDocumentBlinkComponent } from "../../steps/check-document-blink/check-document-blink.component";

@NgModule({
    declarations: [
        AlertModalComponent,
        LoadingComponent,
        LoadingModalComponent,
        RequestLocationModalComponent,
        IverificationComponent,
        CheckDocumentFrontComponent,
        VerifyComponent,
        CheckDocumentBackComponent,
        CheckDocumentBlinkComponent,
        ResultsComponent,
        StepListFlowComponent,
        DocumentCaptureComponent,
        AboutStepComponent,
        PermissionsStepComponent,
        PermissionDeniedStepComponent,
        FingerprintStepComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        HttpClientModule,
        ImageCropperModule,
        TranslateModule,
        GetErrorPipe,
    ],
    exports: [
        AlertModalComponent,
        LoadingComponent,
        LoadingModalComponent,
        MatExpansionModule,
        RequestLocationModalComponent,
        IverificationComponent,
        CheckDocumentFrontComponent,
        VerifyComponent,
        CheckDocumentBackComponent,
        CheckDocumentBlinkComponent,
        ResultsComponent,
        StepListFlowComponent,
        DocumentCaptureComponent,
        AboutStepComponent,
        PermissionsStepComponent,
        PermissionDeniedStepComponent,
        FingerprintStepComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonsModule {
}
