import { Injectable } from "@angular/core";
import { ControlTransitionStep, KeyStep, StatusStep, StepInterface, TransitionStep } from "./step.interface";

@Injectable({
    providedIn: "root",
})
export class PermissionsService implements StepInterface {
    label: string;
    key: KeyStep;
    status: StatusStep;
    transitionBackwards: TransitionStep;
    transitionForward: TransitionStep;
    cues: string;
    cbNextStep: () => void;

    constructor() {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this.label = "step-permissions.title";
        this.key = KeyStep.PERMISSIONS;
        this.status = StatusStep.NA;
        this.transitionBackwards = {
            label: "ATRAS",
            control: ControlTransitionStep.DISABLED,
        };
        this.transitionForward = {
            label: "Continuar",
            control: ControlTransitionStep.MANUAL,
        };
        this.cues = ``;
    }


    async onSetup(): Promise<void> {
    }

    async onEnter(): Promise<void> {
    }

    async onProgress(): Promise<void> {
    }

    async onLeave(): Promise<void> {
    }
}
