import { environment } from "../../environments/environment";

/* It's a class that has a static property called api that is either the current hostname or the
jaakApi value from the environment file */

export class Config {
    static api = environment.apiDynamic
        ? `${window.location.protocol}//${window.location.host}`
        : environment.api;
    static tyrona = environment.tayrona;
}