export const environment = {
    production: false,
    staging: false,
    apiDynamic: false,
    api: "https://dev.api.jaak.ai",
    apiOneToOne: "https://facade-1to1-http-635t26xtnq-uc.a.run.app",
    urlWeb: "https://dev.kyc.jaak.ai/",
    urlDashboard: "https://dev.jaak.ai/",
    tayrona: "https://dev.api.tayrona.jaak.ai",
    firebaseConfig: {
        apiKey: "AIzaSyCB6LFIE89STEczcCP9JGIMw-uHPkmzjT4",
        authDomain: "jaakrecog-1to1-similarity.firebaseapp.com",
        projectId: "jaakrecog-1to1-similarity",
        storageBucket: "jaakrecog-1to1-similarity.appspot.com",
        messagingSenderId: "19169445718",
        appId: "1:19169445718:web:ef05261dce9b978434b8f7",
        measurementId: "G-SH7GVCW22L",
    },
    blinkKey: "sRwCAA9kZXYua3ljLmphYWsuYWkGbGV5SkRjbVZoZEdWa1QyNGlPakUzTWpVeU5EUXlNVFV4TXpVc0lrTnlaV0YwWldSR2IzSWlPaUl3TUdabU1ERmxaUzAyTXpKakxUUmlNR0V0WlRneVppMDFOVEEwWXpFek4yVmlaRE1pZlE9PWMIQeKyVtC05vu32s8lhGH+2P9HgzSPHRJoRKOxpRWxpd38a8dRnNIfDV0le4oOx0l5FmZKF+9T0pkZkQ1/oP0+PRBXLu6Z8TGjSJh9UZtFeuQz16669Syh+wfI/LuPPBcuRj7PnzVgJ6ZpEilNFfp7v3o="
};
