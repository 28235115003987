export interface StepInterface {
    label: string;
    key: KeyStep;
    status: StatusStep;
    cues: string;
    transitionBackwards: TransitionStep;
    transitionForward: TransitionStep;
    cbNextStep: () => void;
    stepNumber?: number;
    showStepper?: boolean;

    onSetup(...params: any[]): Promise<void>;

    onEnter(): Promise<void>;

    onProgress(): Promise<void>;

    onLeave(): Promise<void>;

}

export enum KeyStep {
    ABOUT = "ABOUT",
    PERMISSIONS = "PERMISSIONS",
    DOCUMENT_CAPTURE = "DOCUMENT_CAPTURE",
    TC = "TC",
    CHECK_DOCUMENT_FRONT = "CHECK_DOCUMENT_FRONT",
    CHECK_DOCUMENT_BACK = "CHECK_DOCUMENT_BACK",
    VERIFY = "VERIFY",
    FINGERPRINT = "FINGERPRINT",
    IVERIFICATION = "IVERIFICATION",
    ONETOONE = "ONETOONE",
    BLACKLIST = "BLACKLIST",
    RESULTS = "RESUTS",
    PERMISSION_DENIED = "PERMISSION_DENIED",
}

export enum StatusStep {
    NA = "NA",
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    FAILED = "FAILED",
}

export enum ControlTransitionStep {
    DISABLED = "DISABLED",
    MANUAL = "MANUAL",
    AUTOMATIC = "AUTOMATIC",
}


export interface TransitionStep {
    label: string;
    control: ControlTransitionStep;
}
