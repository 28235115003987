import {Expose, plainToInstance, Type} from "class-transformer";
import * as _ from "lodash"
import {BLACKLIST_REQUEST} from "./blacklist-request";

export class BlackList {
    name: string;
    notes: Array<string>;
}

export interface BlackListRequest {
    name: string;
    surname: string;
    motherSurname?: string;
    rfc: string;
    curp: string;
}

export class BLRequest {
    @Expose()
    @Type(() => ServiceBlacklistRequest)
    services: ServiceBlacklistRequest
    @Expose()
    @Type(() => PayloadBlacklistRequest)
    payload: PayloadBlacklistRequest

    static from(payload: Record<string, any> = {}): BLRequest {
        if (_.isEmpty(payload)) {
            payload = BLACKLIST_REQUEST
        }
        return plainToInstance(BLRequest, payload, {strategy: "excludeAll"})
    }
}

export class ServiceBlacklistRequest {
    @Expose()
    ine: boolean
    @Expose()
    interpol: boolean
    @Expose()
    ofac: boolean
    @Expose()
    @Type(() => RenapoServiceBlacklistRequest)
    renapo: RenapoServiceBlacklistRequest
    @Expose()
    @Type(() => SatServiceBlacklistRequest)
    sat: SatServiceBlacklistRequest
    @Expose()
    @Type(() => CDCServiceBlacklistRequest)
    cdc: CDCServiceBlacklistRequest
}

export class RenapoServiceBlacklistRequest {
    @Expose()
    curp: boolean
}

export class SatServiceBlacklistRequest {
    @Expose()
    sat69b: boolean
}

export class CDCServiceBlacklistRequest {
    @Expose()
    rccFico: boolean
}

export class PayloadBlacklistRequest {
    @Expose()
    @Type(() => PersonPayloadBlacklistRequest)
    person: PersonPayloadBlacklistRequest
    @Expose()
    @Type(() => AddressPayloadBlacklistRequest)
    address: AddressPayloadBlacklistRequest
    @Expose()
    @Type(() => IdentificationsPayloadBlacklistRequest)
    identifications: IdentificationsPayloadBlacklistRequest
    @Expose()
    @Type(() => ExtrasPayloadBlacklistRequest)
    extras: ExtrasPayloadBlacklistRequest
}

export class PersonPayloadBlacklistRequest {
    @Expose()
    name: string
    @Expose()
    secondName: string
    @Expose()
    lastName: string
    @Expose()
    secondLastName: string
    @Expose()
    birthDate: string
    @Expose()
    nationality: string
    @Expose()
    additionalLastName: string
}

export class AddressPayloadBlacklistRequest {
    @Expose()
    address: string
    @Expose()
    neighborhood: string
    @Expose()
    municipality: string
    @Expose()
    city: string
    @Expose()
    state: string
    @Expose()
    postalCode: string
}

export class IdentificationsPayloadBlacklistRequest {
    @Expose()
    curp: string
    @Expose()
    rfc: string
    @Expose()
    socialSecurityNumber: string
    @Expose()
    electorKey: string
    @Expose()
    @Type(() => INEIdentificationsPayloadBlacklistRequest)
    ine: INEIdentificationsPayloadBlacklistRequest
}

export class INEIdentificationsPayloadBlacklistRequest {
    @Expose()
    cic: string
    @Expose()
    ocr: string
}

export class ExtrasPayloadBlacklistRequest {
    @Expose()
    commonId: string
    @Expose()
    wantedIn: string
}