import { Injectable } from "@angular/core";
import { Builder } from "builder-pattern";
import { User, UserMeta, UserMetaExtra } from "../models/user.model";
import { Session } from "../models/session/session.model";
import {
    ExtractDocument,
    GeneralData,
    SpecificData,
} from "../models/extract-document";
import { AddressData, DocumentImage, Position } from "../models/document";
import { Company } from "../models/company.model";
import { StateOneToOne, VerifyOto } from "../models/verify";
@Injectable({
    providedIn: "root",
})
export class BuilderPatternService {
    constructor() {}

    public buildUserClass(response): User {
        return Builder(User)
            .id(response.id)
            .fullName(response.fullName)
            .email(response.email)
            .rol(response.rol)
            .meta(null)
            .status(response.status)
            .createdAt(response.createdAt)
            .updatedAt(response.updatedAt)
            .build();
    }

    /**
     * It builds a UserMeta class from a response object.
     * @param response - The response from the API.
     * @returns A UserMeta object with an extra property that is a UserMetaExtra object.
     */
    public buildUserMetaClass(response): UserMeta {
        return Builder(UserMeta)
            .extra(
                Builder(UserMetaExtra)
                    .address(response.address)
                    .campaign(response.campaign)
                    .build(),
            )
            .build();
    }

    /**
     * It builds a Session class from a response object.
     * @param response - The response from the server.
     * @returns A Session object
     */
    public buildSessionClass(response): Session {
        return Builder(Session)
            .accessToken(response.accessToken ?? response.token)
            .user(response.user ? this.buildUserClass(response.user) : null)
            .company(
                response.company
                    ? this.buildCompanyClass(response.company)
                    : null,
            )
            .expirationDate(response.expirationDate ?? null)
            .build();
    }

    public buildCompanyClass(response): Company {
        return Builder(Company)
            .id(response.id)
            .name(response.name)
            .email(response.email)
            .domains(response.domainList)
            .logo(response.logo)
            .seats(response.seats)
            .phone(response.phone)
            .createdAt(response.createdAt)
            .updatedAt(response.updatedAt)
            .build();
    }

    public buildOtoVerifyClass(response): VerifyOto {
        return Builder(VerifyOto)
            .id(response.id)
            .score(response.score)
            .distance(response.distance)
            .codes(response.codes)
            .metadata(response.metadata)
            .process_time(response.process_time)
            .score(response.score)
            .state(
                response.state
                    ? Builder(StateOneToOne)
                          .message(response?.state?.message)
                          .isSamePerson(response?.state?.isSamePerson)
                          .rejectedAccesorires(
                              response?.state?.rejectedAccesorires,
                          )
                          .rejectedBadQuality(
                              response?.state?.rejectedBadQuality,
                          )
                          .build()
                    : null,
            )
            .build();
    }

    public buildDocumentModel(response: any, image): ExtractDocument {
        return Builder(ExtractDocument)
            .id(response.eventId)
            .status(response.status)
            .documentType(response.documentType)
            .documentData({
                generalData: Builder(GeneralData)
                    .address(
                        Builder(AddressData)
                            .street(
                                response.documentData.generalData?.address
                                    ?.street,
                            )
                            .neighborhood(
                                response.documentData.generalData?.address
                                    ?.neighborhood,
                            )
                            .zipCode(
                                response.documentData.generalData?.address
                                    ?.zipCode,
                            )
                            .city(
                                response.documentData.generalData?.address
                                    ?.city,
                            )
                            .state(
                                response.documentData.generalData?.address
                                    ?.state,
                            )
                            .council(
                                response.documentData.generalData?.address
                                    ?.council,
                            )
                            .build(),
                    )
                    .birthDate(response.documentData.generalData?.birthDate)
                    .motherSurname(
                        response.documentData.generalData?.motherSurname,
                    )
                    .name(response.documentData.generalData?.name)
                    .surname(response.documentData.generalData?.surname)
                    .validUntil(response.documentData.generalData?.validUntil)
                    .gender(response.documentData.generalData?.gender)
                    .documentImage(
                        Builder(DocumentImage)
                            .photo(
                                response.documentData.generalData?.documentImage
                                    ?.photo,
                            )
                            .position(
                                Builder(Position)
                                    .top(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.top,
                                    )
                                    .bottom(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.bottom,
                                    )
                                    .left(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.left,
                                    )
                                    .right(
                                        response.documentData.generalData
                                            ?.documentImage?.position?.right,
                                    )
                                    .build(),
                            )
                            .build(),
                    )
                    .build(),
                mechanicalReadingZone:
                    response.documentData.mechanicalReadingZone,
                specificData: response.documentData.specificData,
            })
            .documentMetadata(response.documentMetadata)
            .processingTime(response.processingTime)
            .message(response.message ?? null)
            .image(image ?? null)
            .build();
    }
}
