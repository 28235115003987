import { Component, HostBinding } from "@angular/core";
import { FlowService } from "../../services/tools/flow.service";
import { FingerprintService } from "../../services/fingerprint.service";
import { SessionService } from "../../services/session.service";
import { StorageService } from "../../services/storage.service";
import { FixPadding } from "../../enums/base64-padding";
import { Alert } from "../../interfaces/alert";
import { Strings } from "../../classes/messages";
import { ModalController } from "@ionic/angular";
import { ModalService } from "../../services/modal.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-fingerprint-step",
    templateUrl: "./fingerprint-step.component.html",
    styleUrls: ["./fingerprint-step.component.scss"],
})
export class FingerprintStepComponent {
    reader: { setup: () => void };

    loading = true;

    deviceNotFound = false;

    @HostBinding("class")
    hostClass = "step-wrapper";

    constructor(
        public flowSrv: FlowService,
        private fingerprintService: FingerprintService,
        public sessionSrv: SessionService,
        private storageService: StorageService,
        private modalCtrl: ModalController,
        private modalsSrv: ModalService,
        private translate: TranslateService,
    ) {
    }

    async next() {
        await this.flowSrv.goToNextStep();
    }

    async sample(event: CustomEvent) {
        const document = await this.storageService.getDocument();
        const fingerprint = (event.detail + FixPadding[event.detail.length % 4])
            .replace(/_/g, "/")
            .replace(/-/g, "+");
        const body = {
            fullName: `${document.documentData.generalData.name} ${document.documentData.generalData.surname}`,
            country: this.sessionSrv.session.document.country,
            identification: {
                type: this.sessionSrv.session.document.type || "ine",
                key: "cic",
                value: await this.storageService.getDocumentId(),
            },
            finger: 6,
            fingerprint,
            minQuality: 1
        };
        this.fingerprintService.enroll(body)
            .subscribe({
                next: () => this.next(),
                error: (error) => {
                    const errorMessage = error.errors[0]?.message.includes("bad quality")
                        && this.translate.instant("step-fingerprint.bad-quality");
                    this.throwAlertModal(errorMessage, null);
                },
            });
    }

    connected(): void {
        this.loading = false;
        this.deviceNotFound = false;
    }

    connect(): void {
        this.loading = true;
        this.deviceNotFound = false;
        this.reader.setup();
    }

    disconnected(): void {
        this.deviceNotFound = true;
        this.loading = false;
    }

    async throwAlertModal(message: string, type: string): Promise<void> {
        const alert: Alert = this.createErrorAlert(
            message ? message : Strings.errorUknow,
            type ? type : null,
        );
        await this.modalsSrv.openModalAlert(alert);
    }

    createErrorAlert(errMsg: string = null, type: string = null): Alert {
        const buttonType = type === "unauthorized" ? null : "try";
        return {
            type: "error",
            message: errMsg,
            buttonType,
            buttonFunction: async () => {
                await this.modalCtrl.dismiss();
            },
        };
    }
}
