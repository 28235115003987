// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.step-content {
  padding: 44px 20px;
  gap: 16px;
  justify-content: space-between;
}

.permissions-banner {
  max-width: 120px;
  width: 90%;
  margin: 0 auto;
}

.description-container {
  max-width: 700px;
  width: calc(100% - 16px);
  margin: 0 auto;
}

p {
  margin-bottom: 0;
}

ul {
  padding-left: 14px;
}

a {
  color: var(--contrast-primary-color, var(--ion-color-primary, #3880ff)) !important;
}

.permissions-button {
  max-width: 445px;
  width: calc(100% - 16px);
  margin: 0 auto;
  height: 50px;
  text-transform: none;
  font-size: 20px;
  font-weight: 700;
  --border-radius: 10px;
  --border-width: 1px;
  --ion-color-base: var(--contrast-primary-color, var(--ion-color-primary, #3880ff)) !important;
}
.permissions-button.button-solid {
  --ion-color-contrast: white !important;
}
.permissions-button .location-icon {
  margin-right: 16px;
}

.granted-icon {
  --ion-color-primary: #00B69B;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/steps/permissions-step/permissions-step.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,8BAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,wBAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kFAAA;AACJ;;AAEA;EACI,gBAAA;EACA,wBAAA;EACA,cAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,mBAAA;EACA,6FAAA;AACJ;AACI;EACI,sCAAA;AACR;AAEI;EACI,kBAAA;AAAR;;AAIA;EACI,4BAAA;EACA,gBAAA;AADJ","sourcesContent":[":host {\n    display: flex;\n    flex-grow: 1;\n    width: 100%;\n    flex-direction: column;\n}\n\n.step-content {\n    padding: 44px 20px;\n    gap: 16px;\n    justify-content: space-between;\n}\n\n.permissions-banner {\n    max-width: 120px;\n    width: 90%;\n    margin: 0 auto;\n}\n\n.description-container {\n    max-width: 700px;\n    width: calc(100% - 16px);\n    margin: 0 auto;\n}\n\np {\n    margin-bottom: 0;\n}\n\nul {\n    padding-left: 14px;\n}\n\na {\n    color: var(--contrast-primary-color, var(--ion-color-primary, #3880ff)) !important;\n}\n\n.permissions-button {\n    max-width: 445px;\n    width: calc(100% - 16px);\n    margin: 0 auto;\n    height: 50px;\n    text-transform: none;\n    font-size: 20px;\n    font-weight: 700;\n    --border-radius: 10px;\n    --border-width: 1px;\n    --ion-color-base: var(--contrast-primary-color, var(--ion-color-primary, #3880ff)) !important;\n\n    &.button-solid {\n        --ion-color-contrast: white !important;\n    }\n\n    .location-icon {\n        margin-right: 16px;\n    }\n}\n\n.granted-icon {\n    --ion-color-primary: #00B69B;\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
