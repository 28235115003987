import "reflect-metadata";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import "@jaak.ai/document-detector";
import "@jaak.ai/face-detector";

if (environment.production) {
    enableProdMode();
}

localStorage.clear();

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
