// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.step-content {
  height: 100%;
  padding: 44px 30px 16px 30px;
}

app-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 9999999;
  justify-content: center;
  background: white;
  box-shadow: 1px 1px 4px 0 rgba(32, 41, 69, 0.1019607843);
}

p {
  font-size: 13px;
  font-weight: 400;
}

h2 {
  font-weight: bold;
  color: #323232;
  text-align: center;
  margin-bottom: 20px;
}

ion-img {
  max-width: 120px;
  margin: 0 auto 20px auto;
}

@media (max-width: 767px) {
  .comparare-alert {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/steps/results/results.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,4BAAA;AACF;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,iBAAA;EACA,wDAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,wBAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[":host {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-direction: column;\n}\n\n.step-content {\n  height: 100%;\n  padding: 44px 30px 16px 30px;\n}\n\napp-loading {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  z-index: 9999999;\n  justify-content: center;\n  background: white;\n  box-shadow: 1px 1px 4px 0 #2029451A;\n}\n\np {\n  font-size: 13px;\n  font-weight: 400;\n}\n\nh2 {\n  font-weight: bold;\n  color: #323232;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\nion-img {\n  max-width: 120px;\n  margin: 0 auto 20px auto;\n}\n\n@media (max-width: 767px) {\n  .comparare-alert {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
