import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { ModalController } from "@ionic/angular";
import { ModalService } from "./modal.service";
import { OneToOneService } from "./one-to-one.service";
import { StorageService } from "./storage.service";
import { ToolsService } from "./tools.service";
import { FlowService } from "./tools/flow.service";
import { first } from "rxjs/operators";
import { Alert } from "../interfaces/alert";
import { Strings } from "../classes/messages";
import { KeyStep } from "./steps/step.interface";
import { CustomError } from "../classes/custom-error";

@Injectable({
    providedIn: "root",
})
export class VerifyIdentyService {
    constructor(
        private authSrv: AuthenticationService,
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private oneToOneSrv: OneToOneService,
        private storageSrv: StorageService,
        private toolsSrv: ToolsService,
        public flowSrv: FlowService,
    ) {}

    async verifyIdentity(): Promise<{ score: number; approve: boolean }> {
        return new Promise(async (resolve, reject) => {
            try {
                const data = {
                    eventId: await this.storageSrv.getEventId(),
                    image1: await this.storageSrv.getIneFrontImage(),
                    image2: await this.storageSrv.getBestFrame(),
                };

                const res = await this.oneToOneSrv
                    .oneToOneVerify(data)
                    .pipe(first())
                    .toPromise();
                const images = {
                    image1: `data:image/jpg;base64,${data.image1}`,
                    image2: `data:image/jpg;base64,${data.image2}`,
                };
                if (
                    res.state &&
                    (res.state?.isSamePerson === false ||
                        res.state?.rejectedAccesorires ||
                        res.state?.rejectedBadQuality)
                ) {
                    resolve({ score: res.score, approve: false });
                }

                resolve({ score: res.score, approve: true });
            } catch (e) {
                if (e.statusCode === 401) {
                    await this.authSrv.logout();
                    setTimeout(() => {
                        this.toolsSrv.goRouteLoginPage();
                    }, 150);

                    reject(e);
                    throw new Error(e);
                }

                if (e.statusCode === 400) {
                    resolve({
                        score: 0,
                        approve: false,
                    });
                }
                reject(e);
                throw new Error(e);
            }
        });
    }

    createErrorAlert(errMsg?: string, errType?: string): Alert {
        const buttonType = errType === "unauthorized" ? null : "try";
        return {
            type: errType === "unauthorized" ? "error" : "server",
            message: errMsg ?? Strings.errorServer,
            buttonType,
            buttonFunction: async () => {
                await this.modalCtrl.dismiss();
                await this.flowSrv.goToStepByKey(KeyStep.IVERIFICATION);
            },
        };
    }
}
