// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.step-content {
  padding: 44px 80px;
}

label {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

p {
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .step-content {
    padding: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/steps/verify/verify.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[":host {\n  display: flex;\n  flex-grow: 1;\n  width: 100%;\n  flex-direction: column;\n}\n\n.step-content {\n  padding: 44px 80px;\n}\n\nlabel {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 18px;\n}\n\np {\n  font-size: 14px;\n  font-weight: 400;\n}\n\n@media (max-width: 767px) {\n  .step-content {\n    padding: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
