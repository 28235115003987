import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { Platform } from "@ionic/angular";
import { StorageService } from "../../services/storage.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { Builder } from "builder-pattern";
import { DocumentExtractDocument, ExtractDocument, SiteDocument } from "../../models/extract-document";
import { DOC_ORIENTATION, NgxImageCompressService } from "ngx-image-compress";
import { DocumentDetectorConfig } from "@jaak.ai/document-detector";
import { SessionService } from "../../services/session.service";
import { FileResult } from "@jaak.ai/document-detector/dist/types/interfaces/file-result";
import { ToolsService } from "../../services/tools.service";

@Component({
    selector: "app-check-document-front",
    templateUrl: "./check-document-front.component.html",
    styleUrls: ["./check-document-front.component.scss"],
})
export class CheckDocumentFrontComponent implements OnInit {
    @ViewChild("documentDetector")
    documentDetector!: ElementRef<HTMLDocumentDetectorElement>;

    @Output()
    valueResponse: EventEmitter<string> = new EventEmitter();

    ineCaptured: any;

    config: DocumentDetectorConfig = {
        mode: "upload-file",
        accept: "image/*",
        placeholder: "",
        cameraSource: "environment",
        width: "100%",
        height: "100%",
        video: {
            aspectRatio: 16 / 9,
            width: { ideal: 1280, max: 1280, min: 640 },
            height: { ideal: 720, max: 720, min: 360 },
            advanced: [
                { width: { ideal: 1280, exact: 1280 } },
                { width: { exact: 1024 } },
                { width: { exact: 900 } },
                { width: { exact: 800 } },
                { width: { exact: 640 } },
                { width: { exact: 320 } },
            ],
        },
        documentDetect: true,
    };

    newImage: string;

    image: string;
    result: FileResult & {
        filename: string;
        extension: string;
    };

    file: any;

    isDesktop = true;

    streamStatus: string;

    modelStatus: string;

    constructor(
        private storageSrv: StorageService,
        private imageCompress: NgxImageCompressService,
        public sessionSrv: SessionService,
        private toolsService: ToolsService
    ) {
        this.isDesktop = this.toolsService.isDesktop();
        this.config.documentDetect = this.isDesktop;
    }

    async ngOnInit(): Promise<void> {
        this.ineCaptured = await this.storageSrv.getDocument();
    }

    onDocumentDetector(event: any) {
        this.streamStatus = "loading";
        this.image = event.detail.base64;
        this.result = event.detail;
        if (event.detail.name) {
            this.result.filename = event.detail.name?.split(".")[0] + ".";
            this.result.extension = event.detail.name?.split(".")[1];
        }
        setTimeout(() => {
            this.streamStatus = "ended";
        }, 1000);
    }

    onVideoStatus(event: { detail: string }) {
        this.streamStatus = event.detail;
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.newImage = event.base64;
    }

    async verifyCard(): Promise<any> {
        try {
            const eventId = (await this.storageSrv.getEventId()) || null;
            const compressImage = await this.imageCompress.compressFile(
                this.newImage,
                DOC_ORIENTATION.Up,
            );
            const docImage = compressImage.split(",")[1];
            const newDoc = Builder(ExtractDocument)
                .id(eventId)
                .document(
                    Builder(DocumentExtractDocument)
                        .country(this.sessionSrv.session.document.country)
                        .type(this.sessionSrv.session.document.type)
                        .site(SiteDocument.front)
                        .hasBackSide(
                            this.sessionSrv.session.document.hasBackSide,
                        )
                        .includeOCR(this.sessionSrv.session.document.includeOCR)
                        .build(),
                )
                .image(docImage)
                .build();

            this.valueResponse.emit(newDoc as never);
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    public clear() {
        this.image = "";
    }

    async capture() {
        const mode = await this.documentDetector.nativeElement.getMode();
        if (mode === "video-camera") {
            this.documentDetector.nativeElement.takeSnapshot();
        } else {
            this.documentDetector.nativeElement.uploadFile();
        }
    }

    async uploadFile() {
        const mode = await this.documentDetector.nativeElement.getMode();
        const newMode =
            mode === "video-camera" ? "upload-file" : "video-camera";
        await this.documentDetector.nativeElement.switchMode(newMode);
        this.config.mode = newMode;
    }
}
