import { Injectable } from "@angular/core";
import { ControlTransitionStep, KeyStep, StatusStep, StepInterface, TransitionStep } from "./step.interface";

@Injectable({
    providedIn: "root",
})
export class DocumentCaptureReverseService implements StepInterface {
    showStepper = true;
    stepNumber = 3;
    label: string;
    key: KeyStep;
    status: StatusStep;
    transitionBackwards: TransitionStep;
    transitionForward: TransitionStep;
    cues: string;
    cbNextStep: () => void;

    constructor() {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this.label = "step-check-document-back.title";
        this.key = KeyStep.CHECK_DOCUMENT_BACK;
        this.status = StatusStep.NA;
        this.transitionBackwards = {
            label: "",
            control: ControlTransitionStep.DISABLED,
        };
        this.transitionForward = {
            label: "Continuar",
            control: ControlTransitionStep.MANUAL,
        };
        this.cues = ``;
    }


    async onSetup(): Promise<void> {
    }

    async onEnter(): Promise<void> {
    }

    async onProgress(): Promise<void> {
    }

    async onLeave(): Promise<void> {
    }
}
