import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { ViewChild } from "@angular/core";
import { BlinkService } from "../../services/blink.service";
import { TranslateService } from "@ngx-translate/core";
import { FlowService } from "src/app/services/tools/flow.service";
import { KeyStep } from "src/app/services/steps/step.interface";
import { SessionService } from "src/app/services/session.service";
import { firstValueFrom, Subscription } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { Alert } from "../../interfaces/alert";
import { Strings } from "src/app/classes/messages";
import { ModalController } from "@ionic/angular";
import { StorageService } from "src/app/services/storage.service";
import {
    DocumentExtractDocument,
    ExtractDocument,
    SiteDocument,
    StateExtractDocument,
} from "src/app/models/extract-document";
import { DocumentVerify } from "../../models/document";
import { Builder } from "builder-pattern";
import { LivenessService } from "../../services/liveness.service";
import { BlinkIdMultiSideRecognizerResult } from "@microblink/blinkid-in-browser-sdk/types/Recognizers/BlinkID/Generic/BlinkIdMultiSideRecognizer";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";
import { environment } from "../../../environments/environment";
import { CustomError } from "src/app/classes/custom-error";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DocumentSessionSelected } from "src/app/models/session/document-session.model";

@Component({
    selector: "app-check-document-blink",
    templateUrl: "./check-document-blink.component.html",
    styleUrls: ["./check-document-blink.component.scss"],
})
export class CheckDocumentBlinkComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild("videoRef")
    videoRef: ElementRef<HTMLVideoElement>;
    @ViewChild("blinkIdInBrowser")
    blinkIdInBrowser: ElementRef<{ startCameraScan: () => Promise<void> }>;
    message = "";
    minApprovalEvaluation = 0.9;
    blinkSubscription: Subscription | null = null;

    blinkKey = environment.blinkKey;

    translationBlinkId: { [key: string]: string };

    private destroyRef = inject(DestroyRef);

    constructor(
        private _blinkService: BlinkService,
        private translate: TranslateService,
        private flowSrv: FlowService,
        private sessionSrv: SessionService,
        private modalSrv: ModalService,
        private modalCtrl: ModalController,
        private storageSrv: StorageService,
        private livenessSrv: LivenessService,
    ) {}

    ngOnInit() {
        this.translationBlinkId = this.translate.instant("blink-id");
        this.translate.onLangChange
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.translationBlinkId = this.translate.instant("blink-id");
                this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_FRONT);
            });
    }

    ngAfterViewInit() {
        // this.initObservable();
    }

    ngOnDestroy() {
        // this.removeObservable();
    }

    private removeObservable() {
        this.blinkSubscription?.unsubscribe();
        this.blinkSubscription = null;
    }

    private initObservable() {
        this.removeObservable();
        this.blinkSubscription = this._blinkService
            .videoCameraRecognize(this.videoRef.nativeElement)
            .subscribe(
                async (result) => {
                    if (result?.side === "BACK") {
                        this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_BACK);
                    }

                    if (result.type === "RESULTS") {
                        this.removeObservable();

                        if (
                            this.sessionSrv.session.document.hasBackSide &&
                            result.side === "FRONT"
                        ) {
                            setTimeout(() => {
                                this.initObservable();
                                return;
                            }, 500);
                        }

                        // TODO: Debe enviar la petición al backend
                        await this.verifyData(
                            result.data as BlinkIdMultiSideRecognizerResult,
                        );
                    }
                    if (result?.message) {
                        this.message = this.translate.instant(result.message);
                        return;
                    }
                    setTimeout(() => {
                        this.message = "";
                    }, 100);
                },
                () => {},
                console.log,
            );
    }

    public async verifyData(data: BlinkIdMultiSideRecognizerResult) {
        try {
            await this.modalSrv.openModalLoading();
            const documentSessionSelected: DocumentSessionSelected = {
                country: this.sessionSrv.session.document.country,
                type: this.sessionSrv.session.document.type,
            };

            const response: any = await firstValueFrom(
                this._blinkService.extract(data, documentSessionSelected),
            );

            if (response?.state) {
                const state: StateExtractDocument = Builder(
                    StateExtractDocument,
                )
                    .isExpired(response?.state?.isExpired)
                    .isUnderAge(response?.state?.isUnderAge)
                    .supportedDocument(response?.state?.supportedDocument)
                    .message(response?.state?.message)
                    .build();

                if (
                    state.isExpired ||
                    state.isUnderAge ||
                    !state.supportedDocument
                ) {
                    await this.modalSrv.closeLoadingModal();
                    const customError = Builder(CustomError)
                        .eventId(response.id ?? "")
                        .description("")
                        .errorCode("0000")
                        .message(state.message)
                        .statusCode(200)
                        .build();
                    const alert: Alert = this.createErrorAlert(customError);
                    await this.modalSrv.openModalAlert(alert);
                    return;
                }
            }

            await this.storageSrv.setEventId(response.eventId);
            await this.saveDocumentOnStorage(
                response?.documentData?.generalData?.documentImage
                    ?.photo as unknown as string,
                response,
            );

            // await this.verifyDocument(data.fullDocumentFrontImage.encodedImage as unknown as string);

            await this.modalSrv.closeLoadingModal();
            await this.flowSrv.goToStepByKey(KeyStep.VERIFY);
        } catch (error) {
            console.log(error);
            await this.modalSrv.closeLoadingModal();
            const alert: Alert = this.createErrorAlert(error);
            await this.modalSrv.openModalAlert(alert);
        }
    }

    private createErrorAlert(customError: CustomError): Alert {
        const buttonType = customError.errorCode === "401" ? null : "try";
        return {
            type:
                customError.errorCode === "503" ||
                customError.errorCode === "500"
                    ? "server"
                    : "error",
            message: customError?.message
                ? customError.message
                : Strings.errorServer,
            buttonType,
            buttonFunction: async () => {
                this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_FRONT);
                await this.blinkIdInBrowser?.nativeElement?.startCameraScan();
                this.modalCtrl.dismiss();
            },
        };
    }

    private async verifyDocument(docImage: string): Promise<DocumentVerify> {
        const eventId = (await this.storageSrv.getEventId()) || null;
        const frontDocument: ExtractDocument = Builder(ExtractDocument)
            .id(eventId)
            .document(
                Builder(DocumentExtractDocument)
                    .country(this.sessionSrv.session.document.country)
                    .type(this.sessionSrv.session.document.type)
                    .site(SiteDocument.front)
                    .hasBackSide(this.sessionSrv.session.document.hasBackSide)
                    .includeOCR(this.sessionSrv.session.document.includeOCR)
                    .build(),
            )
            .image(docImage)
            .build();

        frontDocument.documentType = {
            country: this.sessionSrv.session.document.country,
            side: SiteDocument.front,
            type: this.sessionSrv.session.document.type,
        };

        return new Promise(async (resolve, reject) => {
            try {
                const verify = await this.livenessSrv
                    .verifyDocument(frontDocument)
                    .toPromise();

                if (!verify.status && verify.message === "NOT IMPLEMENT YET") {
                    resolve(verify);
                } else if (verify.evaluation > this.minApprovalEvaluation) {
                    resolve(verify);
                } else {
                    reject({
                        error: {
                            message: verify.message
                                ? verify.message
                                : "Este documento no se ha podido validar",
                            type: "invalid",
                        },
                    });
                }
            } catch (error) {
                const errorMessage = error?.error?.message;
                if (errorMessage === "NOT IMPLEMENT YET") {
                    const verify = Builder(DocumentVerify).build();
                    resolve(verify);
                }
                reject(error);
            }
        });
    }

    async saveDocumentOnStorage(
        frontImageId: string,
        documentExtracted: ExtractDocument,
    ): Promise<void> {
        await this.storageSrv.setIneFrontImage(frontImageId);
        await this.storageSrv.setDocument(documentExtracted);
    }

    async scanSuccess(value: { recognizer: BlinkIdMultiSideRecognizerResult }) {
        const recognitionResults: BlinkIdMultiSideRecognizerResult =
            value.recognizer;
        if (
            recognitionResults.state !== BlinkIDSDK.RecognizerResultState.Empty
        ) {
            if (recognitionResults.fullDocumentFrontImage.encodedImage) {
                (
                    recognitionResults.fullDocumentFrontImage as any
                ).encodedImage = this._blinkService.base64ArrayBuffer(
                    recognitionResults.fullDocumentFrontImage.encodedImage,
                );
            }
            if (recognitionResults.fullDocumentBackImage.encodedImage) {
                (recognitionResults.fullDocumentBackImage as any).encodedImage =
                    this._blinkService.base64ArrayBuffer(
                        recognitionResults.fullDocumentBackImage.encodedImage,
                    );
            }
            if (recognitionResults.signatureImage.encodedImage) {
                (recognitionResults.signatureImage as any).encodedImage =
                    this._blinkService.base64ArrayBuffer(
                        recognitionResults.signatureImage.encodedImage,
                    );
            }
            if (recognitionResults.faceImage.encodedImage) {
                (recognitionResults.faceImage as any).encodedImage =
                    this._blinkService.base64ArrayBuffer(
                        recognitionResults.faceImage.encodedImage,
                    );
            }
        }
        await this.verifyData(recognitionResults);
    }

    onFirstSide(): void {
        this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_BACK);
    }
}
