import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { CustomError } from "../classes/custom-error";
import { TranslateService } from "@ngx-translate/core";
import { Builder } from "builder-pattern";
import { Strings } from "../classes/messages";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private translate: TranslateService) {}

    handleError(error: any) {
        let formattedError: CustomError;

        if (error instanceof HttpErrorResponse) {
            // Construimos el ErrorFormatter a partir del HttpErrorResponse
            formattedError = this.formatHttpError(error);
        } else {
            error = error.rejection || error; // Para errores no HTTP
            formattedError = this.formatGeneralError(error);
        }

        console.error("Error from global error handler", formattedError);
    }

    private formatHttpError(error: HttpErrorResponse): any {
        const errorResponse = error.error;
        return Builder(CustomError)
            .eventId(errorResponse?.eventId ?? "")
            .errorCode(errorResponse?.errorCode ?? "0")
            .description(errorResponse?.description ?? error.message)
            .message(
                errorResponse?.message ??
                    this.translate.instant(Strings.errorUknow),
            )
            .statusCode(errorResponse?.statusCode ?? error.status)
            .build();
    }

    private formatGeneralError(error: any): any {
        return Builder(CustomError)
            .eventId("")
            .errorCode(error?.errorCode ?? "0")
            .description(
                error?.message ?? this.translate.instant(Strings.errorUknow),
            )
            .message(this.translate.instant(Strings.errorUknow))
            .statusCode(0)
            .build();
    }
}
