import { User } from "../user.model";
import { Company } from "../company.model";
import { Expose, plainToInstance, Transform, Type } from "class-transformer";
import * as moment from "moment";
import { DocumentSession } from "./document-session.model";

export class Session {
    @Expose()
    accessToken: string;

    @Expose()
    user: User;

    @Expose()
    company: Company;

    @Expose()
    @Type(() => DocumentSession)
    document: DocumentSession;

    @Expose()
    @Transform(({ value }) => moment(value), { toClassOnly: true })
    expirationDate: moment.Moment;

    static from(payload: Record<string, any>): Session {
        return plainToInstance(Session, payload, { strategy: "excludeAll" });
    }

    public hasExpired(): boolean {
        if (!this.expirationDate) {
            return false;
        }

        const now = moment();
        return now.isAfter(this.expirationDate);
    }

}
