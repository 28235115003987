import { Injectable } from "@angular/core";
import { ControlTransitionStep, KeyStep, StatusStep, StepInterface, TransitionStep } from "./step.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FingerprintService implements StepInterface {
    showStepper = true;
    stepNumber = 6;
    private forkJoinResults = new BehaviorSubject<any>(null);
    public doc: any;
    public rfc: any;
    public curp: any;
    public label: string;
    public key: KeyStep;
    public status: StatusStep;
    public transitionBackwards: TransitionStep;
    public transitionForward: TransitionStep;
    public cues: string;
    cbNextStep: () => void;

    constructor() {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this.label = "step-fingerprint.title";
        this.key = KeyStep.FINGERPRINT;
        this.status = StatusStep.NA;
        this.transitionBackwards = {
            label: "Atras",
            control: ControlTransitionStep.DISABLED,
        };
        this.transitionForward = {
            label: "Continuar",
            control: ControlTransitionStep.MANUAL,
        };
        this.cues = ``;
    }


    async onSetup(): Promise<void> {
        // interface method
    }

    async onEnter(): Promise<void> {
        // interface method
    }

    async onProgress(): Promise<void> {
        // interface method
    }

    async onLeave(): Promise<void> {
        // interface method
    }
}
