import { Expose, plainToInstance } from "class-transformer";

export class Box {
    @Expose()
    x: number;

    @Expose()
    y: number;

    @Expose()
    width: number;

    @Expose()
    height: number;

    static fromPlain(data: Record<string, any>): Box {
        return plainToInstance(Box, data);
    }
}
