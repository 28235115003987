import { Injectable } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { Strings } from "../classes/messages";
import { AuthenticationService } from "./authentication.service";
import { Observable, of } from "rxjs";
import { Theme } from "../models/theme.model";
import { catchError, first, map } from "rxjs/operators";
import { FaceDetectorService } from "./face-detector.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class AppService {
    private $theme: Theme;

    get theme(): Theme {
        return this.$theme;
    }

    constructor(
        private authSrv: AuthenticationService,
        private faceDetectorSrv: FaceDetectorService,
        private toastCtrl: ToastController,
        private loadingCtrl: LoadingController,
        private translate: TranslateService,
    ) {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this.$theme = null;
    }

    public async initialize(): Promise<void> {
        this.faceDetectorSrv.loadModels().then();
        await this.loadTheme();
        await this.authSrv.factoryLoadUserSession();
    }

    async loadTheme(): Promise<void> {
        const theme = await this.getTheme().pipe(first()).toPromise();
        if (!theme) {
            return;
        }

        const body = document.querySelector("body");
        const contrast = this.invertColor(theme.colors.primary);
        if (contrast !== "#FFFFFF") {
            body.style.setProperty("--ion-color-primary-contrast", contrast);
            body.style.setProperty("--contrast-primary-color", contrast);
        }
        body.style.setProperty("--ion-color-primary", theme.colors.primary);
        body.style.setProperty("--primary-color", theme.colors.primary);
        body.style.setProperty("--secondary-color", theme.colors.secondary);
        body.style.setProperty("--ion-color-color", theme.colors.secondary);
        const favicon: HTMLLinkElement = document.querySelector("link[rel~='icon']");
        favicon.href = theme.pictures.logo;

        this.$theme = theme;
    }

    invertColor(hex: string, bw: boolean = true) {
        if (hex.indexOf("#") === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error("Invalid HEX color.");
        }
        let r: number | string = parseInt(hex.slice(0, 2), 16);
        let g: number | string = parseInt(hex.slice(2, 4), 16);
        let b: number | string = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? "#323232"
                : "#FFFFFF";
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    }

    padZero(str: string, len = 2) {
        const zeros = new Array(len).join("0");
        return (zeros + str).slice(-len);
    }

    private getTheme(): Observable<Theme> {
        return this.authSrv.companyMe().pipe(
            map((theme) => {
                return Theme.from({
                    colors: {
                        primary: theme.customizable?.colors?.primary || "#262E48",
                        secondary: theme.customizable?.colors?.secondary || "#3B82F6"
                    },
                    pictures: {
                        logo: theme.customizable?.images?.logo?.original || "/assets/img/JAAK_Azul.png",
                    },
                    countryList: theme.customizable?.countryList || []
                });
            }),
            catchError(() => of(Theme.from({
                colors: {
                    primary: "#262E48",
                    secondary: "#3B82F6",
                },
                pictures: {
                    logo: "/assets/img/JAAK_Azul.png",
                },
                countryList: []
            }))),
        );
    }

    public async showToast(
        message: string,
        duration = 5000,
        color = "danger",
        position: "top" | "bottom" | "middle" = "top",
    ): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            color,
            duration,
            position,
        });
        return toast.present();
    }

    public async showLoading(
        message = Strings.pleaseAwaitMessage,
        mode: "ios" | "md" = "ios",
    ): Promise<void> {
        const loading = await this.loadingCtrl.create({
            message: this.translate.instant(message),
            mode,
        });
        return loading.present();
    }

    public dismissLoading(): Promise<boolean> {
        return this.loadingCtrl.dismiss();
    }
}

export function appInitializer(appSrv: AppService): () => Promise<void> {
    return async () => {
        await appSrv.initialize();
    };
}
