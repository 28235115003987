import {
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { SessionService } from "../../services/session.service";
import { Country } from "../../models/country/country.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppService } from "../../services/app.service";
import { IonModal } from "@ionic/angular";
import { ICountry } from "../../interfaces/country/country.interface";

@Component({
    selector: "app-document-capture",
    templateUrl: "./document-capture.component.html",
    styleUrls: ["./document-capture.component.scss"],
})
export class DocumentCaptureComponent implements OnInit {
    @ViewChild(IonModal) modal: IonModal;

    @Output()
    valueResponse: EventEmitter<string> = new EventEmitter();

    destroyRef = inject(DestroyRef);

    countrySelected: Country;

    countryList: Country[] = [];

    form: FormGroup;

    constructor(
        public sessionSrv: SessionService,
        public appSrv: AppService,
        private fb: FormBuilder,
    ) {}

    ngOnInit() {
        this.countryList = this.appSrv.theme.countryList;
        this.setup();
    }

    private setup() {
        this.buildForm();
        this.loadDocumentList();
    }

    private buildForm() {
        this.form = this.fb.group({
            country: [
                {
                    value: this.sessionSrv.session?.document.country,
                    disabled: !!this.sessionSrv.session?.document.country,
                },
                [Validators.required],
            ],
            document: ["", [Validators.required]],
        });

        this.form
            .get("country")
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((v) => this.changeCountry(v));

        this.form
            .get("document")
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((v) => this.changeTypeDocument(v));
    }

    private loadDocumentList() {
        const session = this.sessionSrv.session;
        if (!session.document?.country) {
            return;
        }

        this.countrySelected = this.countryList.find(
            (item: ICountry) =>
                item.codes.isoAlpha3 === session.document.country,
        );
    }

    public changeCountry(key: string) {
        this.countrySelected = this.countryList.find(
            (item: ICountry) => item.codes.isoAlpha3 === key,
        );
        this.form.get("document").setValue("");
    }

    public changeTypeDocument(key: string) {
        if (!key) {
            return;
        }

        const documentSelected = this.countrySelected.documentList.find(
            (document) => document.key === key,
        );
        this.sessionSrv.setDocumentSession({
            type: documentSelected.key,
            hasBackSide: documentSelected.hasBackSide,
            includeOCR: documentSelected.verifiable,
            providerType: documentSelected.providers?.microblink?.documentType,
            country: this.countrySelected?.key,
        });
    }

    next(): void {
        this.valueResponse.emit();
    }
}
