import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { ExtractDocument } from "../models/extract-document";
import { Session } from "../models/session/session.model";
import { BuilderPatternService } from "./builder-pattern.service";
import { DocumentSelected } from "../models/document-selected";

const { Storage } = Plugins;

@Injectable({
    providedIn: "root",
})
export class StorageService {
    constructor(private builderPatternSrv: BuilderPatternService) {
    }

    public async setIneFrontImage(image: string): Promise<void> {
        return await Storage.set({
            key: "ineFront",
            value: JSON.stringify(image),
        });
    }

    public async getIneFrontImage(): Promise<string> {
        const image = JSON.parse(
            await (
                await Storage.get({ key: "ineFront" })
            ).value,
        );

        return image;
    }

    public async removeIneFront(): Promise<void> {
        return await Storage.remove({
            key: "ineFront",
        });
    }

    public async setEventId(id: string): Promise<void> {
        return await Storage.set({
            key: "eventId",
            value: JSON.stringify(id),
        });
    }

    public async getEventId(): Promise<string> {
        const eventId = (await Storage.get({ key: "eventId" })).value;
        if (eventId == null) {
            return null;
        }
        return JSON.parse(eventId);
    }

    public async removeEventId(): Promise<void> {
        return await Storage.remove({
            key: "eventId",
        });
    }

    public async setDocument(doc: ExtractDocument): Promise<void> {

        return await Storage.set({
            key: "doc",
            value: JSON.stringify(doc),
        });
    }

    public async getDocument(): Promise<ExtractDocument> {
        const doc = JSON.parse(await (await Storage.get({ key: "doc" })).value);

        return doc;
    }

    public async removeDocument(): Promise<void> {

        return await Storage.remove({
            key: "doc",
        });
    }

    public async setDocumentId(id: string): Promise<void> {
        return await Storage.set({
            key: "documentId",
            value: JSON.stringify(id),
        });
    }

    public async getDocumentId(): Promise<string> {
        const eventId = (await Storage.get({ key: "documentId" })).value;
        if (eventId == null) {
            return null;
        }
        return JSON.parse(eventId);
    }

    public async removeIneBackCaptured(): Promise<void> {
        return await Storage.remove({
            key: "captureBack",
        });
    }

    public async setBestFrame(value: string): Promise<void> {
        return await Storage.set({
            key: "bestFrame",
            value: JSON.stringify(value),
        });
    }

    public async getBestFrame(): Promise<any> {
        const doc = JSON.parse(
            await (
                await Storage.get({ key: "bestFrame" })
            ).value,
        );

        return doc;
    }

    public async removeBestFrame(): Promise<void> {
        return await Storage.remove({
            key: "bestFrame",
        });
    }

    public async setIdentityResponse(value: any): Promise<void> {
        return await Storage.set({
            key: "identityResponse",
            value: JSON.stringify(value),
        });
    }

    public async getIdentityResponse(): Promise<any> {
        const doc = JSON.parse(
            await (
                await Storage.get({ key: "identityResponse" })
            ).value,
        );

        return doc;
    }

    public async removeIdentityResponse(): Promise<void> {
        return await Storage.remove({
            key: "identityResponse",
        });
    }

    public async setSession(session: any): Promise<void> {
        return await Storage.set({
            key: "session",
            value: JSON.stringify(session),
        });
    }

    public async getSession(): Promise<Session> {
        const session = JSON.parse(
            await (
                await Storage.get({ key: "session" })
            ).value,
        );

        if (!session) {
            return null;
        }

        return Session.from(session);
    }

    public async removeSession(): Promise<void> {
        return await Storage.remove({
            key: "session",
        });
    }

    public async setStep(step: string): Promise<void> {
        return await Storage.set({
            key: "step",
            value: JSON.stringify(step),
        });
    }

    public async getStep(): Promise<string> {
        const step = JSON.parse(
            await (
                await Storage.get({ key: "step" })
            ).value,
        );

        return step;
    }

    public async removeStep(): Promise<void> {
        return await Storage.remove({
            key: "flowStep",
        });
    }

    public async setFlowStep(step: string): Promise<void> {
        return await Storage.set({
            key: "flowStep",
            value: JSON.stringify(step),
        });
    }

    public async getFlowStep(): Promise<string> {
        const step = JSON.parse(
            await (
                await Storage.get({ key: "flowStep" })
            ).value,
        );

        return step;
    }

    public async removeFlowStep(): Promise<void> {
        return await Storage.remove({
            key: "flowStep",
        });
    }

    public async removeStorage(): Promise<void> {
        await this.removeDocument();
        await this.removeEventId();
        await this.removeIneFront();
        await this.removeIneBackCaptured();
        await this.removeBestFrame();
        await this.removeStep();
        await this.removeIdentityResponse();
    }

    public clear(): Promise<void> {
        return Storage.clear();
    }
}
