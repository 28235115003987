import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Alert } from "../interfaces/alert";
import { AlertModalComponent } from "../modals/alert-modal/alert-modal.component";
import { LoadingModalComponent } from "../modals/loading-modal/loading-modal.component";
import { RequestLocationModalComponent } from "../modals/request-location-modal/request-location-modal.component";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    constructor(
        public modalCtrl: ModalController,
    ) {
    }

    public async openModalAlert(alert: Alert): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: AlertModalComponent,
            cssClass: "small",
            backdropDismiss: false,
            componentProps: { alert },
        });

        return await modal.present();
    }

    public async openModalRequestLocation(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: RequestLocationModalComponent,
            cssClass: "small",
            backdropDismiss: false,
            id: "location-request",
        });

        return await modal.present();
    }

    public async closeModalRequestLocation(): Promise<void> {
        await this.modalCtrl.dismiss(null, null, "location-request");
    }

    public async openModalLoading(message?: string): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: LoadingModalComponent,
            cssClass: "big",
            backdropDismiss: false,
            componentProps: { message },
            id: "loading",
        });

        return await modal.present();
    }

    public closeLoadingModal(): Promise<boolean> {
        return this.modalCtrl.dismiss(null, null, "loading");
    }
}
