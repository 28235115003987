import { Component, OnInit } from "@angular/core";
import { Validators, UntypedFormGroup, UntypedFormBuilder, AbstractControl } from "@angular/forms";
import { StorageService } from "../../services/storage.service";
import { ExtractDocument, GeneralData } from "../../models/extract-document";
import { FlowService } from "src/app/services/tools/flow.service";
import { VerifyDataService } from "src/app/services/steps/verify-data.service";
import { KeyStep } from "../../services/steps/step.interface";
import { DataDocument } from "../../models/data-document";
import { AppService } from "../../services/app.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-verify",
    templateUrl: "./verify.component.html",
    styleUrls: ["./verify.component.scss"],
})
export class VerifyComponent implements OnInit {
    dataForm: UntypedFormGroup;

    data: ExtractDocument;

    specificValidators = {
        curp: [
            Validators.required,
            Validators.pattern(/\b[A-Z][AEIOU][A-Z]{2}[0-9]{2}(?:0[1-9]|1[0-2])(?:[1-2][0-9]|0[1-9]|3[0-1])[HM][A-Z]{2}[B-DF-HJ-NP-TV-Z]{3}(?:[0-9]|[A-Z])[0-9]\b/),
        ],
    };

    constructor(
        private fb: UntypedFormBuilder,
        public flowSrv: FlowService,
        private storageSrv: StorageService,
        private verifyDataSrv: VerifyDataService,
        private appService: AppService,
        private translateService: TranslateService,
    ) {
        this.flowSrv.goToStepByKey(KeyStep.VERIFY);
    }

    async ngOnInit(): Promise<void> {
        this.data = await this.storageSrv.getDocument();
        this.initForm();
    }

    initForm(): void {
        const userData: GeneralData = this.data.documentData.generalData;

        this.dataForm = this.fb.group({
            name: [userData.name, [Validators.required]],
            lastname: [userData.surname, [Validators.required]],
            surname: [userData.motherSurname, [Validators.required]],
            motherSurname: [userData.motherSurname, []],
            birthdate: [userData.birthDate, [Validators.required]],
            street: [userData.address.street, [Validators.required]],
            suburb: [userData.address.neighborhood, [Validators.required]],
            zipCode: [userData.address.zipCode, [Validators.required]],
            city: [userData.address.city, [Validators.required]],
            state: [userData.address.state, [Validators.required]],
            council: [userData.address.council],
            neighborhood: [userData.address.neighborhood, []],
            validUntil: [userData.validUntil, []],
            extra: this.fb.group(this.data.documentData.specificData?.reduce((p, c) => {
                p[c.label] = [c.value, this.specificValidators[c.label] || []];
                return p;
            }, {}) || {}),
        });
    }


    goToStep4() {
        if (this.dataForm.invalid) {
            this.dataForm.markAllAsTouched();
            this.appService.showToast(this.translateService.instant("errors.form-with-errors"));
            return;
        }
        const value = this.dataForm.value;
        const dataDocument = DataDocument.from({
            ...value,
            ...value.extra,
        });
        this.verifyDataSrv.dataDocument(dataDocument).subscribe(async () => {
            const userData = this.data.documentData.generalData;
            userData.birthDate = value.birthdate;
            userData.address.city = value.city;
            userData.address.council = value.council;
            userData.surname = value.lastname;
            userData.motherSurname = value.motherSurname;
            userData.name = value.name;
            userData.address.neighborhood = value.neighborhood;
            userData.address.street = value.street;
            userData.address.state = value.state;
            userData.address.neighborhood = value.suburb;
            userData.validUntil = value.validUntil;
            userData.address.zipCode = value.zipCode;
            if (this.data.documentData.specificData) {
                for (const sd of this.data.documentData.specificData) {
                    sd.value = value.extra[sd.label];
                }
            }
            this.storageSrv.setDocument(this.data);
            this.storageSrv.setDocumentId(dataDocument.numberIdentificationCredential);
            this.verifyDataSrv.getBlackLists2();
            await this.flowSrv.goToStepByKey(KeyStep.IVERIFICATION);
        });
    }

    trim(control: AbstractControl) {
        control.setValue(control.value.trim());
    }
}
