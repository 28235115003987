import { Expose, plainToInstance } from "class-transformer";

export class DataDocument {
    @Expose()
    name: string;

    @Expose()
    lastname: string;

    @Expose({ name: "surname" })
    secondLastname: string;

    @Expose()
    birthdate: string;

    @Expose()
    street: string;

    @Expose({ name: "suburb" })
    neighborhood: string;

    @Expose()
    zipCode: string;

    @Expose()
    city: string;

    @Expose()
    state: string;

    @Expose({ name: "electorKey" })
    electorKey: string;

    @Expose({ name: "curp" })
    curp: string;

    @Expose({ name: "rfc" })
    rfc: string;

    @Expose({ name: "registerYear" })
    registerYear: string;

    @Expose({ name: "emissionYear" })
    emissionYear: string;

    @Expose({ name: "geoElectoralData.stateCode" })
    stateCode: string;

    @Expose({ name: "geoElectoralData.cityCode" })
    cityCode: string;

    @Expose({ name: "geoElectoralData.sectionCode" })
    sectionCode: string;

    @Expose({ name: "geoElectoralData.localityCode" })
    localityCode: string;

    @Expose({ name: "identificationOCR" })
    identificationOCR: string;

    @Expose({ name: "numIdentificationCredential" })
    numberIdentificationCredential: string;

    static from(payload: Record<string, any>): DataDocument {
        return plainToInstance(DataDocument, payload, { strategy: "excludeAll" });
    }

    toObjectRequest(): any {
        return {
            person: {
                name: this.name,
                lastname: this.lastname,
                secondLastname: this.secondLastname,
                birthdate: this.birthdate,
            },
            address: {
                street: this.street,
                neighborhood: this.neighborhood,
                zipCode: this.zipCode,
                city: this.city,
                state: this.state,
            },
            extra: {
                electorKey: this.electorKey,
                curp: this.curp,
                rfc: this.rfc,
                registerYear: this.registerYear,
                emissionYear: this.emissionYear,
                stateCode: this.stateCode,
                cityCode: this.cityCode,
                sectionCode: this.sectionCode,
                localityCode: this.localityCode,
                identificationOCR: this.identificationOCR,
                numIdentificationCredential: this.numberIdentificationCredential,
            },
        };
    }
}
