export class User {
    id: string;
    fullName: string;
    email: string;
    readonly rol: UserRole;
    meta: UserMeta;
    status: 'active' | 'inactive';
    createdAt: string;
    updatedAt: string;
}
export class UserMeta {
    extra: UserMetaExtra;
}

/* The UserMetaExtra class is a class that contains two properties: address and campaign */
export class UserMetaExtra {
    address: string;
    campaign: string;
}
export type UserRole = 'god' | 'administrator' | 'manager' | 'viewer';
