import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpErrorResponse,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { Strings } from "../classes/messages";
import { catchError, first, switchMap } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { Builder } from "builder-pattern";
import { ToolsService } from "../services/tools.service";
import { StepperService } from "../services/stepper.service";
import { CustomError } from "../classes/custom-error";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class AuthenticationInterceptorService implements HttpInterceptor {
    private static getTypeErrorByStatus(status: number): string {
        switch (status) {
            case 400:
                return Strings.typeErrorUser;
            case 401:
                return Strings.typeErrorUnauthorized;
            case 404:
                return Strings.typeErrorNotFound;
            case 500:
                return Strings.typeErrorRejected;
            default:
                return Strings.typeErrorUnknown;
        }
    }

    constructor(
        private authSrv: AuthenticationService,
        private toolsSrv: ToolsService,
        private stepperSrv: StepperService,
        private injector: Injector,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.authSrv.session.pipe(
            first(),
            switchMap((session) => {
                let request = req;
                if (session) {
                    const step = this.stepperSrv.stepper$.getValue();
                    const translate = this.injector.get(TranslateService);
                    request = req.clone({
                        setHeaders: {
                            authorization: `Bearer ${session.accessToken}`,
                            "X-Jaak-Kyc-Step": this.parseStepFormat(step),
                            language: translate.currentLang || "es",
                        },
                    });
                }

                return next.handle(request).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 403) {
                            this.authSrv.removeUserSession();

                            setTimeout(async () => {
                                this.toolsSrv.goRouteLoginPage();
                                return;
                            }, 100);
                        }

                        if (
                            error.url.includes("/blacklist/investigate") ||
                            error.url.includes("/kyc/session")
                        ) {
                            return throwError(this.formatError(error));
                        }

                        return throwError(this.formatError(error));
                    }),
                );
            }),
        );
    }

    private formatError(error: HttpErrorResponse): CustomError {
        const errorResponse = error?.error ?? error;
        const translate = this.injector.get(TranslateService);
        return Builder(CustomError)
            .eventId(errorResponse?.eventId ?? "")
            .errorCode(errorResponse?.errorCode ?? "0")
            .description(errorResponse?.description ?? error.message)
            .message(
                errorResponse?.message ?? translate.instant(Strings.errorUknow),
            )
            .statusCode(errorResponse?.statusCode ?? error.status)
            .build();
    }

    private parseStepFormat(step: string): string {
        const format = {
            step1: "0",
            step2: "1",
            step3: "2",
            step4: "3",
            step5: "4",
            step6: "5",
            stepList: "6",
            stepResults: "7",
        };
        const formattedStep = format[step];
        return formattedStep;
    }
}
