import { Injectable } from "@angular/core";
import { filter, first, map, switchMap } from "rxjs/operators";
import { BehaviorSubject, from, Observable, of, throwError } from "rxjs";
import { Strings } from "../classes/messages";
import { LoadingController } from "@ionic/angular";
import { StorageService } from "./storage.service";
import { ToolsService } from "./tools.service";
import { NotificationService } from "./notification.service";
import { Session } from "../models/session/session.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as moment from "moment";
import { Config } from "../models/config.model";
import { BuilderPatternService } from "./builder-pattern.service";
import { StepperService } from "./stepper.service";
import { SessionService } from "./session.service";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    private session$: BehaviorSubject<Session> = new BehaviorSubject<Session>(
        null,
    );

    get session(): Observable<Session> {
        return this.session$.asObservable();
    }

    constructor(
        private loadingCtrl: LoadingController,
        private storageSrv: StorageService,
        private toolsSrv: ToolsService,
        private notifySrv: NotificationService,
        private http: HttpClient,
        private builderPatternSrv: BuilderPatternService,
        private stepperSrv: StepperService,
        private sessionSrv: SessionService,
    ) {
        this.listenUserSession();
    }

    public async factoryLoadUserSession(): Promise<void> {
        const loadingRef = await this.loadingCtrl.create({
            cssClass: "app-loading",
            message: Strings.pleaseAwaitMessage,
            showBackdrop: true,
            spinner: "crescent",
            duration: 0,
        });
        try {
            await loadingRef.present();
            const userSession = await this.getUserSession()
                .pipe(first())
                .toPromise();
            await this.setUserSession(userSession);
            const step = await this.storageSrv.getStep();
            this.updateStepPosition(step);
            await loadingRef.dismiss();
        } catch (error) {
            this.logout();
            await this.notifySrv.showToastDanger(
                error.error ? error.error.message : "Ha ocurrido un error",
            );
            setTimeout(() => {
                this.toolsSrv.goRouteLoginPage();
            }, 100);
            console.log(error);
            await loadingRef.dismiss();
            throw new Error(error);
        }
    }

    public login(email: string, password: string): Observable<Session> {
        const data = { email, password };
        return this.http.post(`${Config.api}/api/v1/sign-in`, data).pipe(
            map((response: any) => {
                response.expirationDate = moment()
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss");
                const session =
                    this.builderPatternSrv.buildSessionClass(response);
                this.updateSessionSubject(session);
                this.storageSrv.setSession(session);
                return session;
            }),
        );
    }

    public companyMe(): Observable<any> {
        return of(JSON.parse(localStorage.getItem("_cap_session"))?.accessToken)
            .pipe(
                map(accessToken => {
                    if (accessToken) {
                        return accessToken;
                    }
                    throw new Error("accessToken not found");
                }),
                switchMap((accessToken) =>
                    this.http.get(`${Config.tyrona}/api/company/me`, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    }),
                ),
            );
    }

    public signInByCode(code: string, device: string): Observable<Session> {
        const headers = new HttpHeaders({
            "Short-Key": code,
            "Origin-Device": device,
        });

        return this.http
            .post(`${Config.api}/api/v1/kyc/session`, {}, { headers })
            .pipe(
                map((response: any) => {
                    response.document = { country: response.document };
                    response.expirationDate = moment()
                        .add(14, "minutes")
                        .format();


                    const session = Session.from(response);

                    this.storageSrv.setSession(session);
                    this.storageSrv.setEventId(response.sessionId);
                    this.updateStepPosition(response.step);
                    this.handlerFeebackData(response.assets);
                    this.updateSessionSubject(session);
                    this.sessionSrv.setSession(session);

                    return session;
                }),
            );
    }

    public updateSessionSubject(session: Session | null): void {
        this.session$.next(session ?? null);
    }

    private listenUserSession(): void {
        this.getUserSession().subscribe(this.setUserSession);
    }

    private getUserSession(): Observable<Session> {
        return from(this.storageSrv.getSession());
    }

    private setUserSession = async (session: Session) => {
        if (session && !session.hasExpired()) {
            try {
                this.session$.next(this.builderPatternSrv.buildSessionClass(session));
                this.sessionSrv.setSession(session);
            } catch (error) {
                console.log(error);
                throw new Error(error);
            }
        } else {
            this.session$.next(null);
        }
    };

    public async logout(): Promise<void> {
        await this.storageSrv.removeStorage();
        await this.removeUserSession();
    }

    public async removeUserSession(): Promise<void> {
        await this.storageSrv.removeSession();
        this.updateSessionSubject(null);
    }

    private async handlerFeebackData(obj: any): Promise<void> {
        const keys = Object.keys(obj);
        for (const key of keys) {
            if (obj[key] === null) {
                return;
            }
            switch (key) {
                case "document":
                    const document = this.builderPatternSrv.buildDocumentModel(
                        obj[key],
                        null,
                    );
                    await this.storageSrv.setDocument(document);
                    await this.storageSrv.setIneFrontImage(document.documentData.generalData.documentImage?.photo
                        ?.length
                        ? document.documentData.generalData.documentImage
                            ?.photo
                        : null);
                    break;
                case "liveness":
                    const liveness = obj[key];
                    await this.storageSrv.setBestFrame(liveness.bestFrame);
                    break;
            }
        }
    }

    private updateStepPosition(step: string): void {
        if (step) {
            const formattedStep = this.parseStepFormat(step);
            this.stepperSrv.updateStep(formattedStep);
            return;
        }
        this.stepperSrv.updateStep("step1");
    }

    private parseStepFormat(step: string): string {

        const format = {
            "0": "step1",
            "1": "step2",
            "2": "step3",
            "3": "step4",
            "4": "step5",
            "5": "step6",
            "6": "stepList",
            "7": "stepResults",
        };
        const formattedStep = format[step];

        return formattedStep ?? step;
    }
}