import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthenticationGuardService } from "./guards/authentication-guard.service";

const routes: Routes = [
    {
        path: "session",
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
    },
    {
        path: "session/:code",
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
    },
    {
        path: "home",
        loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule),
        canActivate: [AuthenticationGuardService],
    },
    {
        path: "**",
        redirectTo: "home",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
