// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.permission-denied-banner {
  max-width: 120px;
  width: 90%;
  margin: 50px auto 62px auto;
}

.description-container {
  max-width: 750px;
  width: calc(100% - 52px);
  margin: 0 auto;
}
.description-container .gray-container {
  background: rgba(50, 50, 50, 0.0509803922);
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  border-radius: 5px;
}
.description-container p {
  font-size: 13px;
  color: rgba(50, 50, 50, 0.6);
  font-weight: 400;
  text-align: center;
}

.permission-denied-button {
  max-width: 445px;
  width: calc(100% - 52px);
  margin: 28px auto 28px auto;
  height: 50px;
  text-transform: none;
  font-size: 20px;
  font-weight: 700;
  --border-radius: 10px;
  --border-width: 1px;
}
.permission-denied-button .location-icon {
  margin-right: 16px;
}

.step-action-buttons ion-button {
  --border-width: 1px;
  --border-color: #3232324D;
}

.permissions-by-browser {
  display: flex;
  align-items: center;
  gap: 30px;
}
.permissions-by-browser ion-img {
  width: 55px;
  min-width: 55px;
  height: 55px;
  object-fit: contain;
}
.permissions-by-browser p {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/steps/permission-denied-step/permission-denied-step.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;EACA,2BAAA;AACF;;AAEA;EACE,gBAAA;EACA,wBAAA;EACA,cAAA;AACF;AACE;EACE,0CAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AAEE;EACE,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;;AAKA;EACE,gBAAA;EACA,wBAAA;EACA,2BAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,mBAAA;AAFF;AAIE;EACE,kBAAA;AAFJ;;AAOE;EACE,mBAAA;EACA,yBAAA;AAJJ;;AAQA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AALF;AAOE;EACE,WAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;AALJ;AAQE;EACE,gBAAA;AANJ","sourcesContent":[":host {\n  display: flex;\n  flex-grow: 1;\n  width: 100%;\n  flex-direction: column;\n}\n\n.permission-denied-banner {\n  max-width: 120px;\n  width: 90%;\n  margin: 50px auto 62px auto;\n}\n\n.description-container {\n  max-width: 750px;\n  width: calc(100% - 52px);\n  margin: 0 auto;\n\n  .gray-container {\n    background: #3232320D;\n    padding: 10px 20px;\n    text-align: center;\n    font-size: 16px;\n    font-weight: 400;\n    color: #323232;\n    border-radius: 5px;\n  }\n\n  p {\n    font-size: 13px;\n    color: #32323299;\n    font-weight: 400;\n    text-align: center;\n  }\n}\n\n\n.permission-denied-button {\n  max-width: 445px;\n  width: calc(100% - 52px);\n  margin: 28px auto 28px auto;\n  height: 50px;\n  text-transform: none;\n  font-size: 20px;\n  font-weight: 700;\n  --border-radius: 10px;\n  --border-width: 1px;\n\n  .location-icon {\n    margin-right: 16px;\n  }\n}\n\n.step-action-buttons {\n  ion-button {\n    --border-width: 1px;\n    --border-color: #3232324D;\n  }\n}\n\n.permissions-by-browser {\n  display: flex;\n  align-items: center;\n  gap: 30px;\n\n  ion-img {\n    width: 55px;\n    min-width: 55px;\n    height: 55px;\n    object-fit: contain;\n  }\n\n  p {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
