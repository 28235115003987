import { Expose } from "class-transformer";

export class DocumentSession {
    @Expose()
    country: string;

    @Expose()
    type: string;

    @Expose()
    hasBackSide: boolean;

    @Expose()
    includeOCR: boolean;

    @Expose()
    providerType: number;
}

export class DocumentSessionSelected {
    @Expose()
    country: string;
    @Expose()
    type: string;
}
