import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Builder } from "builder-pattern";
import { DashboardEventClient } from "../models/event";
import { Event, FinishResponse } from "../models/event.model";

@Injectable({
    providedIn: "root",
})
export class DashboardService {
    contPage: number = 0;
    translateServices = {
        "http://dev.api.jaakrecog.com/api/v1/oauth/": "AUTH",
        "http://dev.api.jaakrecog.com/api/v2/document/extract/": "EXTRACT",
        "http://dev.api.jaakrecog.com/api/v2/document/verify/":
            "VERIFY DOCUMENT",
        LIVENESS: "LIVENESS",
        ONE_TO_ONE: "ONE TO ONE",
        BLACK_LIST: "BLACK LIST",
        "http://dev.api.jaakrecog.com/api/dashboard/events/": "EVENTS",
    };

    constructor(private http: HttpClient) {
    }

    public generateSesion(): Observable<any> {
        const data = {
            apiKey: "ae00738e523998b0c782b06c2c2314675ff01fe1710b006dd3f3f22b6e4ca7388445c16d3b837b7ad89b0ab1ee10ec336def3780d916f6bc103dc380ec0d4df7",
        };
        return this.http.post(`${environment.api}//api/v1/session/`, data).pipe(
            map((response: any) => {
                response.jwt;
                console.log(response.jwt);
            }),
        );
    }

    public getEventById(eventId: string): Observable<Array<Event>> {
        let data = {
            event_id: eventId,
        };

        return this.http
            .get(`${environment.api}/api/v1/kyc/flow/${eventId}`)
            .pipe(
                map((response: { eventList: Array<any> }) => {
                    return response.eventList;
                }),
            );
    }

    public setLocation(latitude: number, longitude: number): Observable<any> {
        return this.http
            .post(`${environment.api}/api/v1/kyc/session/location`, {
                latitude,
                longitude,
            });
    }

    public finishFlow(): Observable<FinishResponse> {
        return this.http.post<FinishResponse>(`${environment.api}/api/v1/kyc/session/finish`, {});
    }

    public getEventsList(userToken: string): Observable<any> {
        let data = {
            page: this.contPage,
        };

        return this.http
            .post(`${environment.api}/api/dashboard/events-by-client`, data)
            .pipe(
                map((response: any) =>
                    Builder(DashboardEventClient)
                        .clientUuid(response.client_uuid)
                        .eventId(response.event_id)
                        .eventsFound(response.eventsFound)
                        .message(response.message)
                        .pagination(response.pagination)
                        .status(response.status)
                        .build(),
                ),
            );
    }

    public getKycEventId(): Observable<string> {
        return this.http.get(`${environment.api}/api/v1/kyc/transaction`).pipe(
            map((response: { id: string }) => {
                return response.id;
            }),
        );
    }
}
