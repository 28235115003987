import { Component, OnInit } from "@angular/core";
import { StorageService } from "../../services/storage.service";
import { DashboardService } from "../../services/dashboard.service";
import { Event } from "src/app/models/event.model";
import { FlowService } from "src/app/services/tools/flow.service";
import { KeyStep } from "../../services/steps/step.interface";
import { VerifyIdentyService } from "../../services/verify-identy.service";
import { BlackList, BLRequest } from "../../models/black-list";
import { Builder } from "builder-pattern";
import { ExtractDocument } from "../../models/extract-document";
import { VerifyDataService } from "../../services/steps/verify-data.service";
import { firstValueFrom } from "rxjs";
import { NotificationService } from "src/app/services/notification.service";
import { CustomError } from "src/app/classes/custom-error";

@Component({
    selector: "app-results",
    templateUrl: "./results.component.html",
    styleUrls: ["./results.component.scss"],
})
export class ResultsComponent implements OnInit {
    showLoading = false;
    eventId: string;
    results: Array<Event> = [];

    identityResponse: {
        score: number;
        approve: boolean;
    } = {
        score: 1,
        approve: true,
    };

    data: any;

    public doc: ExtractDocument;
    public profilePicture: string;
    public lists: Array<BlackList>;
    public rfc: string;
    public curp: string;

    constructor(
        public flowSrv: FlowService,
        private dashboardSrv: DashboardService,
        private storageSrv: StorageService,
        private veryFySrv: VerifyDataService,
        private notificationSrv: NotificationService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showLoading = true;
        this.identityResponse = await this.storageSrv.getIdentityResponse();
        await this.validateIdentityResponse();

        await this.blackListSetup();
        await this.getEventIdFromStorage();
        await this.getResults();
        this.showLoading = false;
    }

    async blackListSetup() {
        this.doc = await this.storageSrv.getDocument();
        this.profilePicture = `data:image/jpg;base64,${this.doc.documentData.generalData.documentImage.photo}`;

        this.veryFySrv.forkJoinResults$.subscribe((results) => {
            if (results) {
                this.getBlackLists(results);
            }
        });
    }

    private async validateIdentityResponse(): Promise<void> {
        const identityOnStorage = await this.storageSrv.getIdentityResponse();
        if (!this.identityResponse && !identityOnStorage) {
            await this.flowSrv.goToStepByKey(KeyStep.IVERIFICATION);
        }
        if (this.identityResponse && !identityOnStorage) {
            await this.storageSrv.setIdentityResponse(this.identityResponse);
        }

        if (!this.identityResponse && identityOnStorage) {
            this.identityResponse = identityOnStorage;
        }
    }

    async getBlackLists(responseList) {
        this.rfc = this.doc.documentData.specificData.find(
            (data) => data.label === "RFC",
        )?.value;
        this.curp = this.doc.documentData.specificData.find(
            (data) => data.label === "CURP",
        )?.value;

        const OFACRequestData = BLRequest.from();
        OFACRequestData.services.ofac = true;
        OFACRequestData.payload.person.name =
            this.doc.documentData.generalData.name;
        OFACRequestData.payload.person.lastName =
            this.doc.documentData.generalData.surname;
        OFACRequestData.payload.person.secondLastName =
            this.doc.documentData.generalData.motherSurname;
        OFACRequestData.payload.extras.wantedIn = "MEX";

        const SATRequestData = BLRequest.from();
        SATRequestData.services.sat.sat69b = true;
        SATRequestData.payload.person.name =
            this.doc.documentData.generalData.name;
        SATRequestData.payload.person.lastName =
            this.doc.documentData.generalData.surname;
        SATRequestData.payload.person.secondLastName =
            this.doc.documentData.generalData.motherSurname;
        SATRequestData.payload.identifications.rfc =
            this.doc.documentData.specificData.find(
                (data) => data.label === "RFC",
            )?.value;

        const interpolRequestData = BLRequest.from();
        interpolRequestData.services.interpol = true;
        interpolRequestData.payload.person.name =
            this.doc.documentData.generalData.name;
        interpolRequestData.payload.person.lastName =
            this.doc.documentData.generalData.surname;
        interpolRequestData.payload.person.secondLastName =
            this.doc.documentData.generalData.motherSurname;
        interpolRequestData.payload.person.nationality = "MEX";
        interpolRequestData.payload.extras.wantedIn = "MEX";

        const CURPRequestData = BLRequest.from();
        CURPRequestData.services.renapo.curp = true;
        CURPRequestData.payload.identifications.curp =
            this.doc.documentData.specificData.find(
                (data) => data.label === "CURP",
            )?.value;

        const INERequestData = BLRequest.from();
        INERequestData.services.ine = true;
        INERequestData.payload.identifications.ine.cic =
            this.doc.documentData.specificData.find(
                (data) => data.label === "Num. Identificación",
            )?.value;
        INERequestData.payload.identifications.ine.ocr =
            this.doc.documentData.specificData.find(
                (data) => data.label === "OCR",
            )?.value;

        this.lists = [
            Builder(BlackList).name("OFAC").notes([responseList[0]]).build(),
            Builder(BlackList).name("SAT").notes([responseList[1]]).build(),
            Builder(BlackList)
                .name("Interpol")
                .notes([responseList[2]])
                .build(),
            Builder(BlackList).name("CURP").notes([responseList[3]]).build(),
            Builder(BlackList).name("INE").notes([responseList[4]]).build(),
        ];
    }

    private async getEventIdFromStorage(): Promise<void> {
        this.eventId = await this.storageSrv.getEventId();
    }

    async getResults(): Promise<void> {
        try {
            this.results = await firstValueFrom(this.dashboardSrv.finishFlow());
        } catch (error) {
            console.error(error);
            const customError: CustomError = error;
            this.showLoading = false;
            if (customError?.message) {
                this.notificationSrv.showToastDanger(customError.message);
            }
        }
    }

    async endProcess(): Promise<void> {
        await this.storageSrv.removeStorage();
        await this.storageSrv.removeSession();
        window.location.reload();
    }
}
