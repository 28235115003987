import { Injectable } from "@angular/core";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";
import { RecognizerRunner } from "@microblink/blinkid-in-browser-sdk/types/MicroblinkSDK/DataStructures";
import { BehaviorSubject, Observable } from "rxjs";
import {
    DisplayablePoints,
    DisplayableQuad,
} from "@microblink/blinkid-in-browser-sdk/types/MicroblinkSDK/MetadataCallbacks";
import { BlinkIdMultiSideRecognizer } from "@microblink/blinkid-in-browser-sdk";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BlinkIdMultiSideRecognizerResult } from "@microblink/blinkid-in-browser-sdk/types/Recognizers/BlinkID/Generic/BlinkIdMultiSideRecognizer";
import { DocumentSessionSelected } from "../models/session/document-session.model";

export interface BlinkStatus {
    message: string;
    type: string;
    side: "FRONT" | "BACK";
    data?: object;
}

@Injectable({
    providedIn: "root",
})
export class BlinkService {
    private _wasmSDK: BlinkIDSDK.WasmSDK;
    private _recognizerRunner: RecognizerRunner;
    private _singleSideIDRecognizer: BlinkIdMultiSideRecognizer;
    private _detectionObj: BehaviorSubject<BlinkStatus>;

    side: "FRONT" | "BACK" = "FRONT";

    message: string;

    constructor(private http: HttpClient) {}

    private _initialize(): void {
        if (BlinkIDSDK.isBrowserSupported()) {
            const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
                environment.blinkKey,
            );
            loadSettings.workerLocation =
                "assets/js/resources/BlinkIDWasmSDK.worker.min.js";
            BlinkIDSDK.loadWasmModule(loadSettings).then(
                async (wasmSDK: BlinkIDSDK.WasmSDK) => {
                    this._wasmSDK = wasmSDK;
                },
                (error: any) => {
                    console.log(
                        "Error during the initialization of the SDK!",
                        error,
                    );
                },
            );
        } else {
            console.log("This browser is not supported by the SDK!");
        }
    }

    videoCameraRecognize(
        cameraFeed: HTMLVideoElement,
    ): Observable<BlinkStatus> {
        this.side = "FRONT";
        this.message = "step-check-document-blink.front";
        this._detectionObj = new BehaviorSubject({
            type: "INFO",
            message: "step-check-document-blink.front",
            side: this.side,
        });

        this._recognize(cameraFeed);
        return this._detectionObj.asObservable();
    }

    extract(
        request: object,
        documentSession?: DocumentSessionSelected,
    ): Observable<any> {
        request['documentSessionSelected'] = documentSession ?? {};
        return this.http.post(
            `${environment.api}/api/v3/document/extract`,
            request,
        );
    }

    private async _recognize(cameraFeed: HTMLVideoElement) {
        await this._recognizerRunner?.delete();
        const recognizer = await BlinkIDSDK.createBlinkIdMultiSideRecognizer(
            this._wasmSDK,
        );
        const settings = await recognizer.currentSettings();
        await recognizer.updateSettings({
            ...settings,
            returnEncodedFullDocumentImage: true,
            returnEncodedFaceImage: true,
            returnEncodedSignatureImage: true,
        } as never);
        this._singleSideIDRecognizer = recognizer;
        this._recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
            this._wasmSDK,
            [recognizer],
            true,
            {
                onDetectionFailed: () => {
                    this._messageRecognize({
                        type: "ERROR",
                        message: `step-check-document-blink.${this.side.toLowerCase()}`,
                    });
                },
                onQuadDetection: (quad: DisplayableQuad) => {
                    this._messageRecognize({
                        type: "QUAD",
                        data: quad,
                    });
                },
                onPointsDetection: (pointSet: DisplayablePoints) => {
                    console.log("POINTS", pointSet);
                },
                onFirstSideResult: () => {
                    this.side = "BACK";
                    this._messageRecognize({
                        type: "INFO",
                        message: "step-check-document-blink.change-side",
                    });
                },
                onDebugText: (debugText: string) => {
                    // console.log(debugText);
                },
            },
        );

        const videoRecognizer =
            await BlinkIDSDK.VideoRecognizer.createVideoRecognizerFromCameraStream(
                cameraFeed,
                this._recognizerRunner,
            );

        const processResult = await videoRecognizer.recognize();

        if (processResult !== BlinkIDSDK.RecognizerResultState.Empty) {
            const recognitionResults: BlinkIdMultiSideRecognizerResult & {
                images?: { front: string; back: string };
            } = await this._singleSideIDRecognizer.getResult();
            if (
                recognitionResults.state !==
                BlinkIDSDK.RecognizerResultState.Empty
            ) {
                if (recognitionResults.fullDocumentFrontImage.encodedImage) {
                    (
                        recognitionResults.fullDocumentFrontImage as any
                    ).encodedImage = this.base64ArrayBuffer(
                        recognitionResults.fullDocumentFrontImage.encodedImage,
                    );
                }
                if (recognitionResults.fullDocumentBackImage.encodedImage) {
                    (
                        recognitionResults.fullDocumentBackImage as any
                    ).encodedImage = this.base64ArrayBuffer(
                        recognitionResults.fullDocumentBackImage.encodedImage,
                    );
                }
                if (recognitionResults.signatureImage.encodedImage) {
                    (recognitionResults.signatureImage as any).encodedImage =
                        this.base64ArrayBuffer(
                            recognitionResults.signatureImage.encodedImage,
                        );
                }
                if (recognitionResults.faceImage.encodedImage) {
                    (recognitionResults.faceImage as any).encodedImage =
                        this.base64ArrayBuffer(
                            recognitionResults.faceImage.encodedImage,
                        );
                }
                this._messageRecognize({
                    type: "RESULTS",
                    message: "step-check-document-blink.results-success",
                    data: recognitionResults,
                });
            } else {
                this._messageRecognize({
                    type: "RESULTS_EMPTY",
                    message: "empty-results",
                });
            }
        } else {
            this._messageRecognize({
                type: "ERROR",
                message: "could-not-extract",
            });
        }
        videoRecognizer.releaseVideoFeed();
        this._detectionObj.complete();
    }

    base64ArrayBuffer(arrayBuffer) {
        let base64 = "";
        const encodings =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

        const bytes: any = new Uint8Array(arrayBuffer);
        const byteLength = bytes.byteLength;
        const byteRemainder = byteLength % 3;
        const mainLength = byteLength - byteRemainder;

        let a: any;
        let b: any;
        let c: any;
        let d: any;
        let chunk: any;

        // Main loop deals with bytes in chunks of 3
        for (let i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            // tslint:disable-next-line:no-bitwise
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

            // Use bitmasks to extract 6-bit segments from the triplet
            // tslint:disable-next-line:no-bitwise
            a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
            // tslint:disable-next-line:no-bitwise
            b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
            // tslint:disable-next-line:no-bitwise
            c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
            // tslint:disable-next-line:no-bitwise
            d = chunk & 63; // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder === 1) {
            chunk = bytes[mainLength];
            // tslint:disable-next-line:no-bitwise
            a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            // tslint:disable-next-line:no-bitwise
            b = (chunk & 3) << 4; // 3   = 2^2 - 1

            base64 += encodings[a] + encodings[b] + "==";
        } else if (byteRemainder === 2) {
            // tslint:disable-next-line:no-bitwise
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
            // tslint:disable-next-line:no-bitwise
            a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
            // tslint:disable-next-line:no-bitwise
            b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            // tslint:disable-next-line:no-bitwise
            c = (chunk & 15) << 2; // 15    = 2^4 - 1

            base64 += encodings[a] + encodings[b] + encodings[c] + "=";
        }

        return base64;
    }

    private _messageRecognize(options: {
        type: string;
        message?: string;
        data?: object;
    }) {
        const { type, message, data } = options;
        this.message = message || this.message;
        this._detectionObj.next({
            type,
            side: this.side,
            message: this.message,
            data,
        });
    }
}
