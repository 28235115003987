import { Injectable } from "@angular/core";
import { ControlTransitionStep, KeyStep, StatusStep, StepInterface, TransitionStep } from "./step.interface";
import { BLRequest, BlackListRequest } from "src/app/models/black-list";
import { BehaviorSubject, Observable, forkJoin, from, of } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, first } from "rxjs/operators";
import { StorageService } from "../storage.service";
import { DataDocument } from "../../models/data-document";

@Injectable({
    providedIn: "root",
})
export class VerifyDataService implements StepInterface {
    showStepper = true;
    stepNumber = 4;
    private forkJoinResults = new BehaviorSubject<any>(null);
    public forkJoinResults$ = this.forkJoinResults.asObservable();
    public label: string;
    public key: KeyStep;
    public status: StatusStep;
    public transitionBackwards: TransitionStep;
    public transitionForward: TransitionStep;
    public cues: string;
    cbNextStep: () => void;

    constructor(private http: HttpClient, private storageSrv: StorageService) {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this.label = "step-verify.title";
        this.key = KeyStep.VERIFY;
        this.status = StatusStep.NA;
        this.transitionBackwards = {
            label: "Atras",
            control: ControlTransitionStep.DISABLED,
        };
        this.transitionForward = {
            label: "Continuar",
            control: ControlTransitionStep.MANUAL,
        };
        this.cues = ``;
    }


    async onSetup(): Promise<void> {
    }

    async onEnter(): Promise<void> {
    }

    async onProgress(): Promise<void> {
    }

    async onLeave(): Promise<void> {
    }

    public investigate(data: BLRequest): Observable<any> {
        return this.http.post(`${environment.api}/api/v2/blacklist/investigate`, data);
    }

    async getBlackLists2() {
        const doc = await this.storageSrv.getDocument();
        const generalData = doc.documentData.generalData;
        const specificData = doc.documentData.specificData;
        const rfc = doc.documentData.specificData.find(
            (data) => data.label === "rfc",
        )?.value;
        const curp = doc.documentData.specificData.find(
            (data) => data.label === "curp",
        )?.value;


        const blackListRequest: Array<Promise<any>> = [];

        const OFACRequestData = BLRequest.from();
        OFACRequestData.services.ofac = true;
        OFACRequestData.payload.person.name = generalData.name;
        OFACRequestData.payload.person.lastName = generalData.surname;
        OFACRequestData.payload.person.secondLastName = generalData.motherSurname;
        OFACRequestData.payload.extras.wantedIn = "MEX";
        const OFACRequest = this.investigate(OFACRequestData).pipe(first()).toPromise();
        blackListRequest.push(OFACRequest);

        const SATRequestData = BLRequest.from();
        SATRequestData.services.sat.sat69b = true;
        SATRequestData.payload.person.name = generalData.name;
        SATRequestData.payload.person.lastName = generalData.surname;
        SATRequestData.payload.person.secondLastName = generalData.motherSurname;
        SATRequestData.payload.identifications.rfc = rfc;
        const SATRequest = this.investigate(SATRequestData).pipe(first()).toPromise();
        blackListRequest.push(SATRequest);

        const interpolRequestData = BLRequest.from();
        interpolRequestData.services.interpol = true;
        interpolRequestData.payload.person.name = generalData.name;
        interpolRequestData.payload.person.lastName = generalData.surname;
        interpolRequestData.payload.person.secondLastName = generalData.motherSurname;
        interpolRequestData.payload.person.nationality = "MEX";
        interpolRequestData.payload.extras.wantedIn = "MEX";
        const interpolRequest = this.investigate(interpolRequestData).pipe(first()).toPromise();
        blackListRequest.push(interpolRequest);

        const CURPRequestData = BLRequest.from();
        CURPRequestData.services.renapo.curp = true;
        CURPRequestData.payload.identifications.curp = curp;
        const CURPRequest = this.investigate(CURPRequestData).pipe(first()).toPromise();
        blackListRequest.push(CURPRequest);

        const INERequestData = BLRequest.from();
        INERequestData.services.ine = true;
        INERequestData.payload.identifications.ine.cic = specificData.find((data) => data.label === "numIdentificationCredential")?.value;
        INERequestData.payload.identifications.ine.ocr = specificData.find((data) => data.label === "identificationOCR")?.value;
        const INERequest = this.investigate(INERequestData).pipe(first()).toPromise();
        blackListRequest.push(INERequest);
        this.runPromiseV2(blackListRequest);
    }

    public dataDocument(doc: DataDocument): Observable<DataDocument> {
        const data = doc.toObjectRequest();
        return this.http.put<DataDocument>(`${environment.tayrona}/api/kyc/data-document`, data);
    }

    private runPromiseV2(promiseList): void {
        const observables = promiseList.map((promise) =>
            from(promise).pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        return of(error.error);
                    } else {
                        return of(error);
                    }
                }),
            ),
        );

        forkJoin(observables)
            .subscribe(results => {
                this.forkJoinResults.next(results);
            });
    }


}
