export class Verify {
    id: string;
    status: string;
    evaluation: number;
    facesFound: Array<any>;
    message: string;
    processTime: number;
    state?: StateLiveness;
}

export class BestFrame {
    id: string;
    score: number;
    message: string;
    processTime: number;
    bestFrame: string;
    threshold: number;
    state?: StateLiveness;
}

export class VerifyOneToOne {
    score: number;
    state?: StateOneToOne;
}

export class VerifyOto {
    codes: number[];
    distance: number;
    id: string;
    metadata: Metadata;
    process_time: number;
    score: number;
    state?: StateOneToOne;
}

export interface Metadata {
    image1: Image;
    image2: Image;
}

export interface Image {
    accessories: Accessories;
    image_quality: ImageQuality;
}

export interface Accessories {
    glass: boolean;
    hat: boolean;
    mask: boolean;
    sunGlass: boolean;
}

export interface ImageQuality {
    isCorrectBlur: boolean;
    isCorrectBrightness: boolean;
    isCorrectHeight: boolean;
    isCorrectHorizontalRotation: boolean;
    isCorrectNumberFaces: boolean;
    isCorrectResolution: boolean;
    isCorrectRotation: boolean;
    isCorrectSizeFace: boolean;
    isCorrectVerticalRotation: boolean;
    isCorrectWidth: boolean;
}

export class State {
    message: string;
}

export class StateLiveness extends State {
    isRealPerson: boolean;
}

export class StateOneToOne extends State {
    rejectedBadQuality: boolean;
    rejectedAccesorires: boolean;
    isSamePerson: boolean;
}
