import { Component } from "@angular/core";
import { FlowService } from "../../services/tools/flow.service";
import { KeyStep } from "../../services/steps/step.interface";

@Component({
    selector: "app-permission-denied-step",
    templateUrl: "./permission-denied-step.component.html",
    styleUrls: ["./permission-denied-step.component.scss"],
})
export class PermissionDeniedStepComponent {

    browser = "chrome";

    browserName = {
        chrome: "Chrome",
        safari: "Safari",
        firefox: "Firefox",
        edge: "Edge",
        avast: "Avast Secure Browser",
        opera: "Opera",
    };

    constructor(
        public flowSrv: FlowService,
    ) {
        this.browser = this.getBrowserType();
        if (!this.browserName[this.browser]) {
            this.browser = "chrome";
        }
    }


    getBrowserType() {
        const test = (regExp: RegExp) => {
            return regExp.test(navigator.userAgent);
        };

        if (test(/opr\//i) || !!(window as any).opr) {
            return "opera";
        } else if (test(/edg/i)) {
            return "edge";
        } else if (test(/chrome|chromium|crios/i)) {
            return "chrome";
        } else if (test(/firefox|fxios/i)) {
            return "firefox";
        } else if (test(/safari/i)) {
            return "safari";
        } else if (test(/trident/i)) {
            return "ie";
        } else if (test(/ucbrowser/i)) {
            return "uc";
        } else if (test(/samsungbrowser/i)) {
            return "samsung";
        } else {
            return "Unknown browser";
        }
    }

    async next() {
        await this.flowSrv.goToStepByKey(KeyStep.PERMISSIONS);
    }
}
