import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class StepperService {
    stepper$: BehaviorSubject<
        | "step0"
        | "step1"
        | "step2"
        | "step3"
        | "step4"
        | "step5"
        | "step6"
        | "stepList"
        | "stepResults"
    > = new BehaviorSubject<
        | "step0"
        | "step1"
        | "step2"
        | "step3"
        | "step4"
        | "step5"
        | "step6"
        | "stepList"
        | "stepResults"
    >("step0");

    constructor(private storageSrv: StorageService) {
    }

    get stepper(): Observable<string> {
        return this.stepper$.asObservable();
    }

    updateStep(value: any): void {
        this.storageSrv.setStep(value);
        return this.stepper$.next(value);
    }
}
