export class Strings {
    static successLiveness = "messages.success-liveness";
    static errorServer = "messages.error-server";
    static pleaseAwaitMessage = "messages.please-await-message";
    static errorUknow = "messages.error-uknow";
    static typeErrorUser = "user_data";
    static typeErrorUnauthorized = "unauthorized";
    static typeErrorNotFound = "not_found";
    static typeErrorRejected = "rejected_by_server";
    static typeErrorUnknown = "unknown";
    static unknownError = "messages.unknown-error";
}
