import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FlowService } from "src/app/services/tools/flow.service";
import { AppService } from "../../services/app.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-step-list-flow",
    templateUrl: "./step-list-flow.component.html",
    styleUrls: ["./step-list-flow.component.scss"],
})
export class StepListFlowComponent implements OnInit, OnDestroy {
    @Input("step") step: string;
    private _onChangeStepSubscription: Subscription | null = null;

    lang = "es";

    constructor(
        public flowSrv: FlowService,
        public appSrv: AppService,
        private translate: TranslateService,
    ) {
        this.lang = this.translate.currentLang || "es";
    }

    ngOnInit(): void {
        this._onChangeStepSubscription = this.flowSrv.onChangeStep
            .subscribe(step => {
                this.scrollToHtmlElement(step.key);
            });
        const currentHost = window.location.hostname;
    }

    ngOnDestroy(): void {
        if (this._onChangeStepSubscription) {
            this._onChangeStepSubscription.unsubscribe();
        }
    }


    changeLang(lang: string) {
        this.translate.use(lang);
        this.lang = lang;
    }

    private scrollToHtmlElement(key: string): void {
        const element = document.getElementById(key);
        if (!element) {
            return;
        }
        const parentNode = element.parentNode as HTMLElement;

        if (!parentNode) {
            return;
        }
        const elementPosition =
            element.offsetLeft -
            (parentNode.offsetWidth - element.offsetWidth) / 2;

        parentNode.scroll({
            left: elementPosition,
            behavior: "smooth",
        });
    }


}
