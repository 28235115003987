import { AddressData, DocumentImage } from "./document";
import { State } from "./verify";

export class ExtractDocument {
    id: string;
    status: boolean;
    message: string;
    documentType: ExtractDocumentType;
    documentData: ExtractDocumentData;
    documentMetadata: string;
    processingTime: number;
    document: DocumentExtractDocument;
    image?: string;

    toObjectRequest(): any {
        return {
            eventId: this.id,
            document: this.image,
            documentType: this.documentType,
            extra: {
                country: this.document.country,
                type: this.document.type,
                side: this.document.site,
                hasBackSide: this.document.hasBackSide,
                includeOCR: this.document.includeOCR,
            },
        };
    }

    isBackDocument(): boolean {
        return this.documentType.side === SiteDocument.back;
    }

    requiresBackDocument(): boolean {
        return ["INE", "IFE"].includes(this.documentType.type);
    }

    isDocumentSupported(): boolean {
        return ["INE", "IFE"].includes(this.documentType?.type);
    }
}

export class DocumentExtractDocument {
    country: string;
    type: string;
    site: SiteDocument;
    hasBackSide: boolean;
    includeOCR: boolean;
}

export enum SiteDocument {
    front = "FRONT",
    back = "BACK",
}

export class GeneralData {
    name: string;
    surname: string;
    motherSurname: string;
    address: AddressData;
    validUntil: string;
    birthDate: string;
    documentImage: DocumentImage;
    rfc?: string;
    curp?: string;
    gender?: string;
}

export interface SpecificData {
    label: string;
    value: string;
}

export interface ExtractDocumentData {
    generalData: GeneralData;
    mechanicalReadingZone: string;
    specificData: Array<SpecificData>;
}

export class Position {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export class DocumentVerify {
    eventId: string;
    status: boolean;
    evaluation: number;
    message: string;
}

export interface ExtractDocumentType {
    country: string;
    side: string;
    type: string;
}

export class StateExtractDocument extends State {
    isExpired: boolean;
    isUnderAge: boolean;
    supportedDocument: boolean;
}
