export const BLACKLIST_REQUEST = {
  "services": {
    "ine": false,
    "interpol": false,
    "ofac": false,
    "renapo": {
      "curp": false
    },
    "sat": {
      "sat69b": false
    },
    "cdc": {
      "rccFico": false
    }
  },
  "payload": {
    "person": {
      "name": "",
      "secondName": "",
      "lastName": "",
      "secondLastName": "",
      "birthDate": "",
      "nationality": "",
      "additionalLastName": ""
    },
    "address": {
      "address": "",
      "neighborhood": "",
      "municipality": "",
      "city": "",
      "state": "",
      "postalCode": ""
    },
    "identifications": {
      "curp": "",
      "rfc": "",
      "socialSecurityNumber": "",
      "electorKey": "",
      "ine": {
        "cic": "",
        "ocr": ""
      }
    },
    "extras": {
      "commonId": "",
      "wantedIn": ""
    }
  }
}