// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fingerprint-container {
  display: flex;
  gap: 30px;
  justify-content: space-around;
  margin: auto 0;
}
.fingerprint-container .fingerprint-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 273px;
  justify-content: space-between;
}
.fingerprint-container .fingerprint-item span {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.fingerprint-container .fingerprint-item p {
  font-size: 16px;
  font-weight: 700;
  color: #323232;
  display: flex;
  align-items: center;
  gap: 30px;
}
.fingerprint-container .fingerprint-item ion-progress-bar {
  width: calc(100% - 32px);
  max-width: 200px;
  border-radius: 10px;
}
.fingerprint-container .fingerprint-card {
  box-shadow: 0 10px 20px 0 rgba(32, 41, 69, 0.0509803922);
  border-radius: 10px;
}

.button-outline {
  --border-width: 1px;
  --border-color: #3232324D;
}

.fingerprint-full-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fingerprint-full-message ion-img {
  max-width: 226px;
}`, "",{"version":3,"sources":["webpack://./src/app/steps/fingerprint-step/fingerprint-step.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,6BAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,8BAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AACN;AAEI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAAN;AAGI;EACE,wBAAA;EACA,gBAAA;EACA,mBAAA;AADN;AAKE;EACE,wDAAA;EACA,mBAAA;AAHJ;;AAOA;EACE,mBAAA;EACA,yBAAA;AAJF;;AAOA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAJF;AAME;EACE,gBAAA;AAJJ","sourcesContent":[".fingerprint-container {\n  display: flex;\n  gap: 30px;\n  justify-content: space-around;\n  margin: auto 0;\n\n  .fingerprint-item {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    min-width: 273px;\n    justify-content: space-between;\n\n    span {\n      margin-top: 12px;\n      font-size: 16px;\n      font-weight: 400;\n      color: #00000099;\n    }\n\n    p {\n      font-size: 16px;\n      font-weight: 700;\n      color: #323232;\n      display: flex;\n      align-items: center;\n      gap: 30px;\n    }\n\n    ion-progress-bar {\n      width: calc(100% - 32px);\n      max-width: 200px;\n      border-radius: 10px;\n    }\n  }\n\n  .fingerprint-card {\n    box-shadow: 0 10px 20px 0 #2029450D;\n    border-radius: 10px;\n  }\n}\n\n.button-outline {\n  --border-width: 1px;\n  --border-color: #3232324D;\n}\n\n.fingerprint-full-message {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n\n  ion-img {\n    max-width: 226px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
