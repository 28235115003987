import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ModalService } from "../../services/modal.service";
import { StorageService } from "../../services/storage.service";
import { AppService } from "../../services/app.service";
import { first } from "rxjs/operators";
import { LivenessService } from "../../services/liveness.service";
import { Video } from "../../classes/video.class";
import { Alert } from "../../interfaces/alert";
import { Strings } from "../../classes/messages";
import { FlowService } from "../../services/tools/flow.service";
import { KeyStep } from "../../services/steps/step.interface";
import { TranslateService } from "@ngx-translate/core";
import { ConfigComponent, FaceDetectionMessage } from "@jaak.ai/face-detector";
import { ToolsService } from "../../services/tools.service";
import { VerifyIdentyService } from "../../services/verify-identy.service";
import { NotificationService } from "src/app/services/notification.service";
import { CustomError } from "src/app/classes/custom-error";
import { firstValueFrom } from "rxjs";
import { Builder } from "builder-pattern";

@Component({
    selector: "app-iverification",
    templateUrl: "./iverification.component.html",
    styleUrls: ["./iverification.component.scss"],
})
export class IverificationComponent implements OnInit {
    @ViewChild("container", { static: true })
    containerElement: ElementRef<HTMLDivElement>;
    @ViewChild("content", { static: true })
    contentElement: ElementRef<HTMLDivElement>;
    @ViewChild("detector", { static: true })
    detectorElement: ElementRef<HTMLFaceDetectorElement>;
    message = "";

    configDetector: ConfigComponent = {
        mode: "video-camera",
        timerStyles: {
            width: 80,
            height: 80,
            fontSize: 40,
            posY: 70,
        },
        width: "100%",
        height: "100%",
        offlineModel: true,
        validateCamera: true,
    };

    status: string;

    constructor(
        public flowSrv: FlowService,
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private storageSrv: StorageService,
        private livenessSrv: LivenessService,
        private appSrv: AppService,
        private translate: TranslateService,
        private toolsService: ToolsService,
        private verifyIdentyService: VerifyIdentyService,
        private notificationsSrv: NotificationService,
    ) {}

    async ngOnInit(): Promise<void> {}

    async ngAfterContentInit(): Promise<void> {
        await this.setup();
    }

    onFaceDetectionMessage(event: any) {
        const detection: FaceDetectionMessage = event.detail;

        if (detection.faceExist) {
            if (detection.correctPosition) {
                this.message = this.translate.instant(
                    "step-iverification.not-move",
                );
            } else {
                this.message = this.translate.instant(
                    "step-iverification.get-closer",
                );
            }
        } else {
            this.message = this.translate.instant(
                "step-iverification.face-in-front",
            );
        }
    }

    async onFileResult(event: any) {
        const fileBase64 = event.detail.base64;

        await this.verify(fileBase64);
    }

    public async verify(video: string) {
        await this.detectorElement.nativeElement.stopComponent();
        try {
            const eventId = await this.storageSrv.getEventId();
            const videoUnformatted = Video.removeFormatBase64(video);
            const response = await firstValueFrom(
                this.livenessSrv.verifyBestFrame(videoUnformatted, eventId),
            );
            if (response.state && !response.state?.isRealPerson) {
                const customError = Builder(CustomError)
                    .eventId(response.id ?? "")
                    .description("")
                    .errorCode("0000")
                    .message(response.state.message)
                    .statusCode(200)
                    .build();
                const alert: Alert = this.createErrorAlert(customError);
                await this.modalSrv.openModalAlert(alert);
                return;
            }
            const alert: Alert = this.createSuccessAlert(response.bestFrame);
            await this.modalSrv.openModalAlert(alert);
        } catch (err: any) {
            const customError: CustomError = err as CustomError;
            const alert: Alert = this.createErrorAlert(customError);

            await this.modalSrv.openModalAlert(alert);
            throw new Error(err);
        } finally {
        }
    }

    private async setup(): Promise<void> {
        await this.appSrv.showLoading();

        await this.awaitForElements();

        this.appSrv.dismissLoading().then();
    }

    private awaitForElements(): Promise<void> {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (this.containerElement.nativeElement) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }

    // TODO cambiar o remover por una mejor version
    private createSuccessAlert(bestFrameImage: string): Alert {
        return {
            type: "success",
            message: Strings.successLiveness,
            buttonType: "continue",
            buttonFunction: async () => {
                try {
                    await this.modalCtrl.dismiss();
                    await this.storageSrv.setBestFrame(bestFrameImage);
                    await this.storageSrv.setIdentityResponse(
                        await this.verifyIdentyService.verifyIdentity(),
                    );
                    if (this.toolsService.hasFingerprint()) {
                        await this.flowSrv.goToNextStep();
                    } else {
                    }
                    await this.flowSrv.goToStepByKey(KeyStep.RESULTS);
                } catch (error) {
                    console.log("Error", error);
                    const alert: Alert = this.createErrorAlert(error);
                    await this.modalSrv.openModalAlert(alert);
                    throw new Error(error);
                }
            },
        };
    }

    // TODO cambiar o remover por una mejor version
    private createErrorAlert(customError: CustomError): Alert {
        const buttonType = customError.errorCode === "401" ? null : "try";
        return {
            type:
                customError.errorCode === "503" ||
                customError.errorCode === "500"
                    ? "server"
                    : "error",
            message: customError?.message
                ? customError.message
                : Strings.errorServer,
            buttonType,
            buttonFunction: async () => {
                await this.detectorElement.nativeElement.restartComponent();
                await this.detectorElement.nativeElement.resetFaceDetector();
                this.modalCtrl.dismiss();
            },
        };
    }

    onComponentError(event: any) {
        const error = event?.detail;
        if (error) console.log("ERROR", error);
        console.log("Event", event);
        this.notificationsSrv
            .showToastDanger(
                "Cannot access camera, please allow access on settings",
            )
            .then();

        throw new Error(error);
    }
}
