// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  color: var(--ion-color-primary, #202945);
  font-size: 80px;
}

h3 {
  color: #1ecad3;
  font-size: 20px;
  line-height: 2;
}

p {
  color: #9c9c9e;
  font-size: 15px;
  line-height: 1.4;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
}

.btn-primary {
  height: 60px;
  width: 100%;
  border-radius: 8px;
  color: white;
  font-size: 19px;
  max-width: 308px !important;
}

.alert-content {
  flex-direction: column;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/request-location-modal/request-location-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,eAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,2BAAA;AACJ;;AAEA;EACI,sBAAA;EACA,YAAA;AACJ","sourcesContent":["ion-icon {\n    color: var(--ion-color-primary,#202945);\n    font-size: 80px;\n}\n\nh3 {\n    color: #1ecad3;\n    font-size: 20px;\n    line-height: 2;\n}\n\np {\n    color: #9c9c9e;\n    font-size: 15px;\n    line-height: 1.4;\n    padding-left: 5rem;\n    padding-right: 5rem;\n    text-align: center;\n}\n\n.btn-primary {\n    height: 60px;\n    width: 100%;\n    border-radius: 8px;\n    color: white;\n    font-size: 19px;\n    max-width: 308px !important;\n}\n\n.alert-content {\n    flex-direction: column;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
