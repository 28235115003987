export class Company {
    id: string;
    email: string;
    name: string;
    phone: string;
    domains: Array<string>;
    seats: number;
    createdAt: string;
    updatedAt: string;
    logo?: string;
  }