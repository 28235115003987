import "reflect-metadata";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import "@jaak.ai/document-detector";
import "@jaak.ai/face-detector";
import * as Sentry from "@sentry/angular";

if (environment.sentry.enable) {
    Sentry.init({
        dsn: environment.sentry.dsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: environment.sentry.tracesSampleRate,
        tracePropagationTargets: environment.sentry.tracePropagationTargets,
        replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
        environment: environment.sentry.environment
    });
}

if (environment.production) {
    enableProdMode();
}

localStorage.clear();

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
