import { Component } from "@angular/core";
import { FlowService } from "../../services/tools/flow.service";

@Component({
    selector: "app-about-step",
    templateUrl: "./about-step.component.html",
    styleUrls: ["./about-step.component.scss"],
})
export class AboutStepComponent {
    constructor(
        public flowSrv: FlowService,
    ) {
    }

    async next() {
        await this.flowSrv.goToNextStep();
    }
}
