// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.step-content {
  padding: 44px 67px;
}

@media (max-width: 767px) {
  .step-content {
    padding: 22px 16px;
  }
}
h2 {
  color: #323232;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

p, strong {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Open Sans", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/steps/about-step/about-step.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE;IACE,kBAAA;EACF;AACF;AAEA;EACE,cAAA;EACA,gBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,yBAAA;EACA,oCAAA;AAAF","sourcesContent":[":host {\n  display: flex;\n  flex-grow: 1;\n  width: 100%;\n  flex-direction: column;\n}\n\n.step-content {\n  padding: 44px 67px;\n}\n\n@media (max-width: 767px) {\n  .step-content {\n    padding: 22px 16px;\n  }\n}\n\nh2 {\n  color: #323232;\n  font-weight: 700;\n  font-family: \"Open Sans\", sans-serif;\n}\n\np, strong {\n  color: #00000099;\n  font-family: \"Open Sans\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
