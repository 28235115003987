import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { Session } from "../models/session/session.model";

@Injectable({
    providedIn: "root",
})
export class AuthenticationGuardService implements CanActivate {
    pathPublicList: Array<string> = ["/session", "/signup"];

    constructor(
        private authSrv: AuthenticationService,
        private $router: Router,
    ) {
    }

    canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        routerStateSnapshot: RouterStateSnapshot,
    ): Observable<boolean> {
        const path = routerStateSnapshot.url;
        return this.authSrv.session.pipe(
            map((session: Session) => {
                if (session) {
                    if (this.isPublicPath(path)) {
                        this.redirectToHome();
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (this.isPublicPath(path)) {
                        return true;
                    } else {
                        this.redirectToLogin();
                        return false;
                    }
                }
            }),
        );
    }

    private redirectToLogin(): void {
        this.$router.navigate(["/session"]).then();
    }

    private redirectToHome(): void {
        this.$router.navigate(["/home"]).then();
    }

    private isPublicPath(path: string): boolean {
        return !!this.pathPublicList.find((p) => {
            return path.startsWith(p);
        });
    }
}
